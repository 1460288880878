import request from '@/utils/request'

export function getSize(path) {
  return request({
    url: '/Api/file/size',
    method: 'get',
    params: { path }
  })
}
export function chunkDownload(path, name, start, end, signal) {
  return request({
    url: '/Api/file/chunkDownload',
    method: 'get',
    params: { path, name,range:`bytes=${start}-${end - 1}` },
    responseType: 'blob',
    signal
  })
}
