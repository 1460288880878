<template>
  <div>
    <div class="head">
      <el-button type="primary" @click="showdialog">新建</el-button>
      <el-input placeholder="按照产品型号搜索搜素" class="Fuzzy_search" v-model="Search"></el-input>
      <el-button type="primary" @click="FuzzySearch">搜索</el-button>
      <span style="margin-left: 10px">筛选key:</span>
      <el-radio-group v-model="sortKey" >
        <el-radio label="productId" style="margin-left: 10px">订货号</el-radio>
        <el-radio label="name" style="margin-left: 10px">产品型号</el-radio>
      </el-radio-group>
    </div>
    <div class="Main">
      <div class="tablediv">
        <el-table :data="ProductData" style="width: 100%" ref="comptable">
          <el-table-column type="expand">
            <template slot-scope="props">
              <el-table style="width: 90%;margin-left:5%" :data="props.row.bomarr" :default-sort="{prop: 'bom.number', order: 'ascending'}">
                <el-table-column prop="bom.Id" label="物料名称" width="380" align="left">
                </el-table-column>
                <el-table-column prop="bom.parts" label="物料型号" align="left">
                </el-table-column>
                <el-table-column prop="bom.number" label="订货号" align="left">
                </el-table-column>
                <el-table-column prop="num" label="消耗" width="180" align="left">
                </el-table-column>
              </el-table>
              <!-- <el-form
                label-position="top"
                inline
                style="margin-left: 10px;"
                class="demo-table-expand"
                id="bomform"
              >
                <el-form-item
                  :label="'名称:'+item.bom.Id +'|消耗:'+item.num  "
                  v-for="item in props.row.bomarr"
                  :key="item._id"
                >
                  <el-card>
                    <div>物料型号:{{ item.bom.parts }}</div>
                    <div>订单号:{{ item.bom.number }}</div> -->
              <!-- <el-popconfirm
                      title="确定停用？"
                      @confirm="deleteBom(props.row, item.bom._id)"订单号
                    >
                      <el-button slot="reference" type="danger" size="mini"
                        >停用物料</el-button
                      >
                    </el-popconfirm> -->
              <!-- </el-card>
                </el-form-item>
              </el-form> -->
            </template>
          </el-table-column>
          <el-table-column prop="name" label="产品型号" width="180" align="left">
          </el-table-column>
          <el-table-column prop="Input" label="输入规格" width="180" align="left">
          </el-table-column>
          <el-table-column prop="productId" label="订货号" align="left">
          </el-table-column>
          <el-table-column prop="Bottomstring" label="标签底部文字信息" align="center">
          </el-table-column>
          <el-table-column label="产品文档" align="center">
            <template slot-scope="scope">
              <el-button type="text" @click="showfile(scope.row)">附件</el-button>
            </template>
          </el-table-column>

          <el-table-column label="问题记录" align="center">
            <template slot-scope="scope">
              <el-button type="text" @click="showIssue(scope.row)">问题</el-button>
            </template>
          </el-table-column>
          <el-table-column prop="identifier" label="序列号标识符" align="center">
          </el-table-column>
          <el-table-column prop="Hw" label="硬件版本" align="center">
          </el-table-column>
          <el-table-column prop="KD" label="KD表" align="center">
            <template slot-scope="scope">
              <el-button type="text" @click="showKDDialog(scope.row)">编辑</el-button>
            </template>
          </el-table-column>
          <el-table-column prop="tip" label="描述" align="center">
          </el-table-column>
          <el-table-column prop="Ce_pass" label="ce标识符" align="center">
            <template slot-scope="scope">
              <el-checkbox v-model="scope.row.Ce_pass" @change="Ce_pass_change(scope.row)">
              </el-checkbox>
            </template>
          </el-table-column>
          <el-table-column width="300" label="操作">
            <template slot-scope="scope">
              <el-button size="mini" type="primary" @click="handleEditInfo(scope.$index, scope.row)">编辑信息</el-button>
              <el-button size="mini" type="primary" @click="handleEdit(scope.$index, scope.row)">编辑硬件版本</el-button>
              <!-- <el-button
                size="mini"
                type="success"
                @click="AddbomShow(scope.$index, scope.row)"
                >添加bom</el-button
              > -->
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <el-dialog :title="!Editmode ? '添加product' : '修改product'" :visible.sync="dislogvisial" width="30%" :close-on-click-modal="false" center>
      <el-form :model="ShowProduct" label-width="120px" v-loading="dialogloading" :rules="addrules">
        <el-form-item label="产品名称" prop="name">
          <el-input v-model="ShowProduct.name"></el-input>
        </el-form-item>
        <el-form-item label="输入规格" prop="Input">
          <el-input v-model="ShowProduct.Input"></el-input>
        </el-form-item>
        <el-form-item label="订货号">
          <el-input v-model="ShowProduct.productId"></el-input>
        </el-form-item>
        <el-form-item label="标签底部文字">
          <el-input v-model="ShowProduct.Bottomstring"></el-input>
        </el-form-item>
        <el-form-item label="标识符" prop="identifier">
          <el-input v-model="ShowProduct.identifier"></el-input>
        </el-form-item>
        <el-form-item label="硬件版本" prop="Hw">
          <el-input v-model="ShowProduct.Hw"></el-input>
        </el-form-item>
        <el-form-item label="产品描述" prop="tip">
          <el-input v-model="ShowProduct.tip"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="creativeProduct()" v-if="!Editmode">立即创建</el-button>
          <el-button type="primary" @click="ChangeProduct()" v-if="Editmode">修改</el-button>
          <el-button @click="dislogvisial = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog title="硬件版本修改" :visible.sync="HwUpdate" width="80%" center>
      <el-row>
        <el-col :span="12">
          <el-timeline>
            <el-timeline-item v-for="(item, index) in showHwChangePath" :key="index" :timestamp="gettimestamps(item.time)" placement="top">
              <el-card>
                <div slot="header" class="clearfix">
                  <el-button @click="selectShowBom(item.bom, item.version)" style=" padding: 3px 0" type="text">Bom查看</el-button>
                </div>
                <h4>HW:{{ item.version ? item.version : "修改Bom无更新" }}</h4>
                <pre>{{ item.describes }}</pre>
                <p>修改于{{ gettimestamps(item.time) }}</p>
              </el-card>
            </el-timeline-item>
            <el-timeline-item placement="top">
              <el-card>
                <div slot="header" class="clearfix">
                  <el-button @click="selectActShowBom()" style=" padding: 3px 0" type="text">Bom查看</el-button>
                </div>
                <el-input v-model="newHwinfo.version" placeholder="请输入新版本" onkeyup="value=value.replace(/[^\d.]/g,'')"></el-input>
                <el-input type="textarea" :rows="2" placeholder="请输入修改描述" v-model="newHwinfo.describes">
                </el-input>
              </el-card>
            </el-timeline-item>
          </el-timeline>
        </el-col>
        <el-col :span="12">
          <h2>版本号：{{ actVersion }}</h2>
          <el-table style="width: 100%;margin-left: 10px;" :data="actBomarr">
            <el-table-column prop="bom.Id" label="物料名称" width="180" align="center">
            </el-table-column>

            <el-table-column prop="bom.number" label="订货号" align="center">
            </el-table-column>
            <el-table-column prop="num" label="消耗" width="60" align="center">
            </el-table-column>
            <el-table-column v-if="isNewBom" label="操作" width="80" align="center">
              <template slot-scope="scope">
                <el-button size="mini" type="error" @click="bomDelete(scope.$index, actBomarr)">删除</el-button>
              </template>

            </el-table-column>
          </el-table>
          <el-form :inline="true" v-if="isNewBom" :model="newBom" style="width: 100%;margin-left: 10px;">
            <el-form-item label="物料">
              <el-select v-model="bomID" placeholder="名称及订货号" @change="handleSelectChange">
                <el-option v-for="item in bomlist" :key="item._id" :value="item._id" :label="item.Id + '_' + item.number"></el-option>

              </el-select>

            </el-form-item>
            <el-form-item label="数量">
              <el-input v-model="newBom.num"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="onSubmit">添加</el-button>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <div id="newhwbutton">
        <el-button type="primary" @click="confirmHwChange">确定修改</el-button>
        <el-button @click="HwUpdate = false">取消</el-button>
      </div>
    </el-dialog>
    <el-dialog title="添加bom" :visible.sync="bomvisiable" width="30%" center>
      <div style="margin-left: 5px; margin-bottom: 10px">订货号：</div>
      <el-input v-model="addBominfo.bomnumber" placeholder="请输入订货号"></el-input>
      <div style="margin-left: 5px; margin-bottom: 10px">消耗：</div>
      <el-input v-model="addBominfo.num" placeholder="请输入数量"></el-input>
      <div style="margin-top: 10px; display: flex; justify-content: flex-end">
        <el-button size="mini" type="primary" @click="confirmAddBom">确定</el-button>
        <el-button size="mini" type="primary" @click="bomvisiable = false">取消</el-button>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="Showannex" :title="productInfo.name">
      <p>版本选择:</p>
      <el-select style="margin:20px;" v-model="versionChoose" @change="getFileList" placeholder="选择版本">
        <el-option v-for="item in productInfo.HwChangePath" :key="item.version" :label="item.version" :value="item.version">
        </el-option>
      </el-select>
      <el-upload action="''" multiple accept=".jpg, .jpeg, .png, .gif, .bmp, .JPG, .JPEG, .PBG, .GIF, .pdf" :http-request="submitList" :file-list="actVersionAppendix" :show-file-list="true"
        :on-remove="handleRemove" :before-remove="beforeRemove" :on-preview="handlePreview">
        <el-button size="small" type="primary">点击上传</el-button>
      </el-upload>
    </el-dialog>

    <el-dialog title="添加问题记录" :visible.sync="showIssueHistory">
      <el-input style="margin-top: 10px" type="text" v-model="newIssue" class="edit-input" size="small">
        <el-button @click="addNewIssue" slot="append" icon="el-icon-circle-plus"></el-button>
      </el-input>
      <el-table :data="productInfo.problemList">
        <el-table-column prop="info" label="问题" align="center">
        </el-table-column>
        <el-table-column label="记录时间" align="center">
          <template slot-scope="scope">
            {{ getDate(scope.row.logDate) }}
          </template>

        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">

            <i class="el-icon-success" v-if="scope.row.status"></i>
            <el-button v-else @click="changeStatus(scope.row)"> 结束</el-button>
          </template>

        </el-table-column>
      </el-table>
    </el-dialog>

    <el-dialog title="编辑KD表" :visible.sync="showKDform">
      <el-table :data="KDInfo" style="width: 100%">
        <el-table-column label="客户" min-width="400" align="center">
          <template slot-scope="scope">
            <el-select v-model="scope.row.OrderNumberAbbr" placeholder="请选择" style="width: 100%;">
              <el-option v-for="item in customerInfo" :key="item._id" :label="item.company" :value="item.OrderNumberAbbr">
              </el-option>
            </el-select>
          </template>
        </el-table-column>

        <el-table-column label="KD号" min-width="200" align="center">
          <template slot-scope="scope">
            <el-input v-model="scope.row.id"></el-input>
          </template>
        </el-table-column>

        <el-table-column label="删除" align="center">
          <template slot-scope="scope">
            <el-button type="danger" size="small" circle @click="remove(scope.$index)" icon="el-icon-close"></el-button>
          </template>
        </el-table-column>
      </el-table>

      <div class="buttonadd">
        <el-button type="primary" icon="el-icon-plus" circle @click="creatNew"></el-button>
      </div>

      <div class="ButtonGroup">
        <el-button type="primary" @click="SaveKDform()">确认修改</el-button>
        <el-button @click="showKDform = false">取消</el-button>
      </div>

    </el-dialog>

  </div>
</template>

<script>
import
{
  GetAllProducts,
  CreativeProduct,
  UpdateHw,
  updateProductinfo,
  Updatebom,
} from "@/api/mesproductinfo";

import { uploadExtra, download, deleteFile } from '@/api/fileTransfer';
import
{
  getMaterialListByid,
  getMaterialListBynumber,
  getMaterialList
} from "@/api/mes";
import { getCustomerList } from "@/api/article";

export default {
  data()
  {
    return {
      showKDform: false,
      customerInfo: [],
      KDInfo: [],
      sortKey:"productId",
      ProductData: [
        {
          name: "",
          Input: "",
          Bottomstring: "",
          Hw: "",
          identifier: "",
          HwChangePath: [],
        },
      ],
      newIssue: "",
      bomID: "",
      Showannex: false,
      productInfo: {},
      isNewBom: false,
      dislogvisial: false,
      actVersion: "",
      dialogloading: false,
      HwUpdate: false,
      actBomarr: [],
      bomlist: [],
      showIssueHistory: false,
      versionChoose: null,
      OpendRow: [],
      Editmode: false,
      ShowProduct: {
        name: "",
        Input: "",
        Bottomstring: "",
        Hw: "",
        identifier: "",
        HwChangePath: [],
      },
      newHwinfo: {
        version: "",
        describes: "",
        time: 0,
      },
      showHwChangePath: [],
      selectHwid: "",
      actVersionAppendix: [],
      GetbomTable: new Map(),
      bomvisiable: false,
      newBom: {
        bom: {},
        num: "1"
      },
      addBominfo: {
        obj: "",
        bomnumber: "",
        num: ""
      },
      addrules: {
        name: [{ required: true, message: "请输入名称", trigger: "blur" }],
        Input: [{ required: true, message: "请输入规格", trigger: "change" }],
        identifier: [
          {
            required: true,
            message: "请输入标识符",
            trigger: "change",
          },
        ],
        Hw: [{ required: true, message: "请输入", trigger: "change" }],
      },
      listQuery: {
        sort: "({productId:1})",
      },
      Search: "",
    };
  },
  created()
  {
    this.getdata();
    this.getCustomerInfo();
  },
  watch:{
    sortKey(newval,oldval){
      this.getdata();
    } 
  },
  methods: {
    Ce_pass_change(row){
      updateProductinfo({
        id: row._id, data: {
          $set: {
            Ce_pass:row.Ce_pass
          }
        }
      }).then(res =>
      {
        this.$message.success("修改成功");
      }).catch(err =>
      {
        this.$message.error("修改失败");
      }).finally(() =>
      {
        this.dislogvisial = false;
        this.getdata();
      });
    },
    async getCustomerInfo()
    {
      const { data } = await getCustomerList();
      this.customerInfo = data.list;
      //  console.log(this.customerInfo);
      const filteredData = this.customerInfo.filter(item => item.OrderNumberAbbr !== null);
      this.customerInfo = filteredData;
      //  console.log(this.customerInfo);
    }, //获取客户数据

    getRowKeys(row)
    {
      return row._id;
    },
    expandChange(row, openrows)
    {
      this.OpendRow = [];
      openrows.forEach((item) =>
      {
        this.OpendRow.push(item);
      });
    },
    bomDelete(index, rows)
    {
      rows.splice(index, 1);
      this.newHwinfo.bomArray = this.actBomarr.concat();
    },
    selectActShowBom()
    {
      this.actVersion = "New";
      this.isNewBom = true;
      this.actBomarr = this.newHwinfo.bomArray.concat();
    },
    async selectShowBom(selectBom, version)
    {
      this.isNewBom = false;
      this.actVersion = version;
      if (selectBom) {
        this.actBomarr = [];

        for (let bom of selectBom) {
          if (!this.GetbomTable.hasOwnProperty(bom)) {
            let res = await getMaterialListByid({ id: bom.id });
            //   const sortedArray = res.sort((a, b) => {
            //   const descriptionA = a[0].description;
            //   const descriptionB = b[0].description;
            //   return descriptionA.localeCompare(descriptionB);
            // });
            if (res) {
              this.GetbomTable.set(bom, res.data);
            }
          }
          this.actBomarr.push({ bom: this.GetbomTable.get(bom), num: bom.num });
        }
      }
    },
    onSubmit()
    {
      let id = this.newBom.bom._id;
      if (id == undefined) {
        return;
      }
      if (this.actBomarr)
        if (this.actBomarr.some(isSame)) {
          this.$message.error("物料重复");
          //   loading.close();
          return;
        }
      this.actBomarr.push({
        bom: this.newBom.bom,
        num: this.newBom.num
      });
      this.newHwinfo.bomArray = this.actBomarr.concat();
      this.bomID = "";

      this.newBom = {
        bom: {},
        num: "1"
      };
      function isSame(bom)
      {

        return bom.bom._id === id;
      }
    },
    async submitList(param)
    {
      var isreaper = false;
      for (var i = 0; i < this.productInfo.appendix.length; i++) {
        if (this.productInfo.appendix[i].name == param.file.name) {
          isreaper = true;
          break;
        }
      }
      if (!isreaper) {
        const formData = new FormData();
        formData.append("ModleName", param.file); // 要提交给后台的文件
        formData.append("projectFolder", this.productInfo._id); // 这个接口必要的项目id
        formData.append("subFolder", null);
        const { data } = await uploadExtra(formData, {
          path: this.productInfo._id + '\\' + this.versionChoose,
        });
        if (data == "success") {
          let obj = {
            name: param.file.name,
            url: "file\\" + this.productInfo._id + '\\' + this.versionChoose + "\\" + param.file.name,
            version: this.versionChoose
          };
          this.$message({
            message: "上传成功",
            type: "success",
          });
          this.productInfo.appendix.push(obj);

          this.saveInfo();
          this.getFileList();
        } else {
          this.$message({
            message: "上传失败",
            type: "warning",
          });
        }
      }
      else {
        this.$message({
          message: "已存在该文件",
          type: "warning",
        });
      }
    },
    async changeStatus(row)
    {
      row.status = true;
      const { data } = await updateProductinfo({
        id: this.productInfo._id,
        data: {
          $set: {

            problemList: this.productInfo.problemList,
          }
        },
      });
    },
    handleSelectChange(selectedItem)
    {
      for (let item of this.bomlist) {
        if (item._id == selectedItem) {
          this.newBom.bom = item;
          break;
        }

      }

    },
    async addNewIssue()
    {
      if (this.newIssue !== "") {
        if (this.productInfo["problemList"] == undefined) {
          this.productInfo["problemList"] = [];
        }
        this.productInfo.problemList.unshift({
          info: this.newIssue,
          logDate: new Date().valueOf(),
          status: false
        });
        const { data } = await updateProductinfo({
          id: this.productInfo._id,
          data: {
            $set: {

              problemList: this.productInfo.problemList,
            }
          },
        });
        this.newIssue = "";
      }
    },
    async handleRemove(file, fileList)
    {
      const { data } = await deleteFile({
        id: this.productInfo._id,
        data: file.url
      });
      if (data == "success") {
        for (let i = 0; i < this.productInfo.appendix.length; i++) {
          if (this.productInfo.appendix[i].name == file.name) {
            this.productInfo.appendix.splice(i, 1);
          }
        }
        this.saveInfo();
      }
    },
    async saveInfo()
    {
      let id = this.productInfo._id;
      const { data } = await updateProductinfo({
        id: id,
        data: {
          $set: {

            appendix: this.productInfo.appendix,
          }
        },
      });



    },
    beforeRemove(file, fileList)
    {
      return this.$confirm(`确定移除 ${file.name}`);
    },
    handlePreview(file)
    {
      this.$confirm(`确定下载 ${file.name}`).then(async res =>
      {
        const data = await download({
          path: file.url,
          filename: file.name
        });
        var url = window.URL.createObjectURL(data);
        var a = document.createElement('a');
        a.href = url;
        a.download = file.name;
        a.click();
        window.URL.revokeObjectURL(url);
      });
    },
    async deleteBom(obj, id)
    {
      // let loading = this.$loading({
      //   lock: true,
      //   text: "Loading",
      //   spinner: "el-icon-loading",
      // });
      let bom = obj.bom;
      obj.bom = bom.filter((item) =>
      {
        return item.id !== id;
      });
      await Updatebom({
        _id: obj._id,
        bom: obj.bom,
      });
      this.getdata();
      // loading.close();
    },
    async confirmAddBom()
    {
      // let loading = this.$loading({
      //   lock: true,
      //   text: "Loading",
      //   spinner: "el-icon-loading",
      // });
      //添加bom 1.查询是否有这个component
      let component = await getMaterialListBynumber({
        number: this.addBominfo.bomnumber,
      });
      //2.如果有component添加没有报错
      if (component.data == null) {
        this.$message.error("没找到物料");
        //   loading.close();
      } else {
        //找打了，添加
        let conp = component.data;
        let _id = conp._id;
        //判断是否重复
        if (this.addBominfo.obj.bom.some(bom => bom.id === _id)) {
          this.$message.error("物料重复");
          //   loading.close();
          return;
        }
        this.GetbomTable.set(_id, conp);
        this.addBominfo.obj.bom.push({
          id: _id,
          num: this.addBominfo.num
        });
        //更新数据
        await Updatebom({
          _id: this.addBominfo.obj._id,
          bom: this.addBominfo.obj.bom
        });
        this.getdata();
        //   loading.close();
        this.bomvisiable = false;
      }
    },
    AddbomShow(index, row)
    {
      this.addBominfo.obj = row;
      this.addBominfo.bomnumber = "";
      this.addBominfo.num = null;
      this.bomvisiable = true;
    },
    showdialog()
    {
      this.Editmode = false;
      this.ShowProduct = {
        name: "",
        Input: "",
        Bottomstring: "",
        Hw: "",
        identifier: "",
        HwChangePath: [],
      };
      this.dislogvisial = true;
    },
    getFileList()
    {
      this.actVersionAppendix = [];
      if (this.versionChoose == null) {
        this.versionChoose = this.productInfo.HwChangePath[0].version;
      }
      for (var i = 0; i < this.productInfo.appendix.length; i++) {
        if (this.productInfo.appendix[i].version == this.versionChoose) {
          this.actVersionAppendix.push(this.productInfo.appendix[i]);
        }
      }
    },
    showfile(input)
    {
      this.productInfo = input;

      if (this.productInfo.HwChangePath.length > 0) {
        this.versionChoose = this.productInfo.HwChangePath[this.productInfo.HwChangePath.length - 1].version;
        this.getFileList();
        this.Showannex = true;
      }
      else {
        this.$message({
          message: "请先添加版本号",
          type: "warning",
        });
      }

    },
    showIssue(input)
    {
      this.productInfo = input;
      this.showIssueHistory = true;
    },

    showKDDialog(input)
    {
      this.KDInfo = JSON.parse(JSON.stringify(input.KD));
      this.productInfo = input;
      this.showKDform = true;
    },

    async getdata()
    {
      GetAllProducts(this.listQuery).then(async (res) =>
      {
        this.ProductData = res.data.list;
        
        for (let item of this.ProductData) {
          let bomlist = [];
          item.bomarr = [];

          for (let bom of item.bom) {
            if (!this.GetbomTable.hasOwnProperty(bom)) {
              let res = await getMaterialListByid({ id: bom.id });
              if (res) {
                this.GetbomTable.set(bom, res.data);
              }
            }
            bomlist.push({ bom: this.GetbomTable.get(bom), num: bom.num });
          }
          item.bomarr = bomlist.sort((a, b) =>
          {
            return a.bom.number > b.bom.number;
          });
        }
      });
      const { data } = await getMaterialList();
      this.bomlist = data.list;
      if(this.sortKey == "name"){
          this.ProductData.sort((a, b) => {
            // 检查是否包含TT
            const hasTTA = a.name.includes("TT");
            const hasTTB = b.name.includes("TT");

            // 如果a包含TT，b不包含TT，则a排在前面
            if (hasTTA && !hasTTB) return -1;
            // 如果a不包含TT，b包含TT，则a排在后面
            if (!hasTTA && hasTTB) return 1;

            // 如果都不包含TT或者都包含TT，则按照ASCII码排序
            return a.name.localeCompare(b.name);
        });
      }
      else{
        this.ProductData.sort((a, b) => {
          return a.productId.localeCompare(b.productId);
        });
      }

    },
    creativeProduct(formName)
    {
      if (
        this.ShowProduct.name == "" ||
        this.ShowProduct.Input == "" ||
        this.ShowProduct.productId == "" ||
        this.ShowProduct.Hw == ""
      ) {
        return;
      } else {
        this.dialogloading = true;
        this.ShowProduct.HwChangePath = [
          {
            version: this.ShowProduct.Hw,
            time: Number(new Date()),
            describes: "创建",
          },
        ];
        CreativeProduct(this.ShowProduct)
          .then((res) =>
          {
            this.$message.success("创建成功");
            this.dislogvisial = false;
            this.dialogloading = false;
            this.getdata();
          })
          .catch((err) =>
          {
            this.$message.error(err);
            this.dialogloading = false;
          });
      }
    },
    async handleEdit(index, row)
    {
      this.showHwChangePath = row.HwChangePath;
      if (this.showHwChangePath == undefined) {
        this.showHwChangePath = [];
      }
      this.actVersion = "New";
      this.isNewBom = true;
      this.newHwinfo = {
        version: "",
        describes: "",
        bomArray: [],
        bom: []
      };
      this.actBomarr = [];
      for (let bom of row.bom) {
        if (!this.GetbomTable.hasOwnProperty(bom)) {
          let res = await getMaterialListByid({ id: bom.id });
          if (res) {
            this.GetbomTable.set(bom, res.data);
          }
        }
        this.newHwinfo.bomArray.push({ bom: this.GetbomTable.get(bom), num: bom.num });

      }

      this.actBomarr = this.newHwinfo.bomArray.concat();
      this.selectHwid = row._id;
      this.HwUpdate = true;
    },
    gettimestamps(time)
    {
      let date = new Date(time);
      var Y = date.getFullYear() + "/";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "/";
      var D =
        (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
      var h =
        (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      var m =
        date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
      return Y + M + D + h + m;
    },
    getDate(timestamp)
    {
      let date = new Date(timestamp),
        y = date.getFullYear(),
        m =
          date.getMonth() + 1 < 10
            ? "0" + (date.getMonth() + 1)
            : date.getMonth() + 1,
        d = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      return y + "年" + m + "月" + d + "日";
    },
    async confirmHwChange()
    {
      let el = this;
      //if (
      //el.showHwChangePath.length > 0 &&  el.newHwinfo.version <=el.showHwChangePath[el.showHwChangePath.length - 1].version
      //) {
      //this.$message.error("不支持版本回退");
      //return;
      //}
      // if (this.newHwinfo.describes == "") {
      //   this.$message.error("请输入修改描述");
      //   return;
      // }
      this.newHwinfo.time = Number(new Date());
      this.newHwinfo.bom = [];
      for (let bom of this.newHwinfo.bomArray) {

        this.newHwinfo.bom.push({ id: bom.bom._id, num: bom.num });
      }
      let temp = this.showHwChangePath;
      temp.push(this.newHwinfo);
      await Updatebom({
        _id: this.selectHwid,
        bom: this.newHwinfo.bom
      });
      UpdateHw({
        _id: this.selectHwid,
        data: temp,
      })
        .then((res) =>
        {
          this.$message.success("更新成功");
          this.showHwChangePath = temp;
          this.HwUpdate = false;
          this.getdata();
        })
        .catch((err) =>
        {
          this.$message.error(err);
        });
    },
    FuzzySearch()
    {
      if (this.Search == null || this.Search == "") {
        this.listQuery.filters = {};
      } else {
        this.listQuery.filters =
          "({$or:[{name:{$regex:/^.*" +
          this.Search +
          ".*$/,$options: 'i'}},{Input:{$regex:/^.*" +
          this.Search +
          ".*$/,$options: 'i'}},{Bottomstring:{$regex:/^.*" +
          this.Search +
          ".*$/,$options: 'i'}},{Hw:{$regex:/^.*" +
          this.Search +
          ".*$/,$options: 'i'}},{identifier:{$regex:/^.*" +
          this.Search +
          ".*$/,$options: 'i'}}]})";
      }
      this.getdata();
    },
    handleEditInfo(index, info)
    {
      this.Editmode = true;
      this.ShowProduct = JSON.parse(JSON.stringify(info));
      this.dislogvisial = true;
    },
    ChangeProduct()
    {
      let id = this.ShowProduct._id;
      updateProductinfo({
        id: id, data: {
          $set: {
            name: this.ShowProduct.name,
            Input: this.ShowProduct.Input,
            productId: this.ShowProduct.productId,
            Bottomstring: this.ShowProduct.Bottomstring,
            Hw: this.ShowProduct.Hw,
            identifier: this.ShowProduct.identifier,
            tip: this.ShowProduct.tip
          }
        }
      }).then(res =>
      {
        this.$message.success("修改成功");
      }).catch(err =>
      {
        this.$message.error("修改失败");
      }).finally(() =>
      {
        this.dislogvisial = false;
        this.getdata();
      });
    },

    creatNew()
    {
      this.KDInfo.push({
        OrderNumberAbbr: null,
        id: null,
      });
    }, //编辑界面新增负责人
    remove(index)
    {
      this.KDInfo.splice(index, 1);
    }, //编辑界面删除负责人
    async SaveKDform()
    {
      const { data } = await updateProductinfo({
        id: this.productInfo._id,
        data: {
          $set: {
            KD: this.KDInfo,
          }
        },
      });
      this.showKDform = false;
      this.getdata();
    }



  },
};
</script>
<style scoped>
.head {
  margin-left: 10px;
  margin-top: 10px;
}

div>>>.Fuzzy_search {
  margin-left: 10px;
  margin-right: 5px;
  display: inline-block;
  width: 300px;
  margin-right: 10px;
}

.tablediv {
  margin-top: 20px;
  width: 100%;
}

#newhw {
  margin-bottom: 10px;
}

#newhwbutton {
  margin-top: 10px;
}

.demo-table-expand {
  font-size: 0;
}

.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}

.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}

.buttonadd {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  margin-right: 10px;
}

.ButtonGroup {
  padding-top: 20px;
  display: flex;
  justify-content: flex-end;
}

#addbom {
  float: right;
}

#bomform .el-form-item {
  min-width: 300px;
  width: auto;
  max-width: 800px;
  margin-right: 20px;
}

#bomform>>>.el-card__body {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: space-around;
  justify-content: space-evenly;
  align-items: center;
}
</style>