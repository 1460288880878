<template>
  <div class="app-container">
    <el-button v-on:click="showFilter = true" type="button">筛选</el-button>
    <el-button v-on:click="newForm">新建</el-button>
    <el-button
      :loading="downloadLoading"
      style="margin: 0 0 20px 20px"
      type="primary"
      icon="el-icon-document"
      @click="handleDownload"
    >
      Export Excel
    </el-button>
    <el-button v-on:click="showExcelUpload = true">导入</el-button>
    <el-button v-on:click="UploadFixFile">导入返修统计</el-button>
    <el-button v-on:click="UploadUPSFile">导入总部回执</el-button>
    <el-input
      class="filterbysn"
      v-model="filterSN"
      placeholder="SN或RepID筛选"
    ></el-input>

    <el-select
      style="margin-left: 10px; width: 30%"
      multiple
      allow-create
      filterable
      v-model="QuickFilterItem"
      @change="QuickFilter"
      placeholder="按模块快速筛选"
    >
      <template slot="prefix">
        <span
          style="
            padding: 5px;
            line-height: 33px;
            font-size: 18px;
            color: #409eff;
          "
        ></span>
      </template>
      <el-option v-for="item in ProdutInfo" :key="item" :value="item">
        <span style="float: left">
          {{ item }}
        </span>
      </el-option>
    </el-select>

    <el-dialog :visible.sync="showExcelUpload" title="导入">
      <upload-excel-component
        :on-success="handleSuccess"
        :before-upload="beforeUpload"
      />
      <el-table
        :data="tableData"
        border
        highlight-current-row
        style="overflow: scroll; height: 1000px; width: 100%; margin-top: 20px"
      >
        <el-table-column
          v-for="item of tableHeader"
          :key="item"
          :prop="item"
          :label="item"
        />
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" v-on:click="bulkSave">添加</el-button>
        <el-button type="primary" v-on:click="bulkUpdate">更新</el-button>
      </span>
    </el-dialog>
    <el-dialog :visible.sync="showDelete" title="删除">
      <h4>确定删除</h4>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" v-on:click="deleteInfo">确定</el-button>
        <el-button type="normal" v-on:click="showDelete = false"
          >取消</el-button
        >
      </span>
    </el-dialog>

    <el-dialog :visible.sync="showFilter" class="filter" title="filter">
      <table class="form">
        <tr>
          <td>
            <p>公司</p>
          </td>
          <td>
            <p>联系人</p>
          </td>
          <td>
            <p>序列号</p>
          </td>
        </tr>
        <tr>
          <td>
            <el-input
              type="text"
              v-model="listInfo.customerInfo.company.data"
              class="bottom-input"
              size="small"
            />
          </td>
          <td>
            <el-input
              type="text"
              v-model="listInfo.customerInfo.contactor.data"
              class="bottom-input"
              size="small"
            />
          </td>
          <td>
            <div>
              <span
                ><el-input
                  type="text"
                  v-model="listInfo.productInfo.SN.dataS"
                  class="bottom-input"
                  size="small"
              /></span>
              ~
              <span
                ><el-input
                  type="text"
                  v-model="listInfo.productInfo.SN.dataE"
                  class="bottom-input"
                  size="small"
              /></span>
            </div>
          </td>
        </tr>
      </table>

      <table>
        <tr>
          <td><p>产品名称</p></td>
          <td><p>产品订货号</p></td>
          <td><p>硬件版本</p></td>
          <td><p>软件版本</p></td>
        </tr>
        <tr>
          <td>
            <el-input
              type="text"
              v-model="listInfo.productInfo.module.data"
              class="bottom-input"
              size="small"
            />
          </td>
          <td>
            <el-input
              type="text"
              v-model="listInfo.productInfo.articl.data"
              class="bottom-input"
              size="small"
            />
          </td>
          <td>
            <el-input
              type="text"
              v-model="listInfo.productInfo.hardware.data"
              class="bottom-input"
              size="small"
            />
          </td>
          <td>
            <el-input
              type="text"
              v-model="listInfo.productInfo.software.data"
              class="bottom-input"
              size="small"
            />
          </td>
        </tr>
        <tr>
          <td><p>退货单号</p></td>
          <td><p>物料号</p></td>
          <td><p>零件编号</p></td>
        </tr>
        <tr>
          <td>
            <el-input
              type="text"
              v-model="listInfo.productInfo.report.data"
              class="bottom-input"
              size="small"
            />
          </td>
          <td>
            <el-input
              type="text"
              v-model="listInfo.productInfo.parts.data"
              class="bottom-input"
              size="small"
            />
          </td>
          <td>
            <el-input
              type="text"
              v-model="listInfo.productInfo.po.data"
              class="bottom-input"
              size="small"
            />
          </td>
        </tr>
      </table>
      <table>
        <tr>
          <td>
            <p>返修日期</p>
            <div>
              <span
                ><el-input
                  type="date"
                  v-model="listInfo.productInfo.return.dataS"
                  class="bottom-input"
                  size="small"
              /></span>
              ~
              <span
                ><el-input
                  type="date"
                  v-model="listInfo.productInfo.return.dataE"
                  class="bottom-input"
                  size="small"
              /></span>
            </div>
          </td>
          <td>
            <p>发货日期</p>
            <div>
              <span
                ><el-input
                  type="date"
                  v-model="listInfo.productInfo.delivery.dataS"
                  class="bottom-input"
                  size="small"
              /></span>
              ~
              <span
                ><el-input
                  type="date"
                  v-model="listInfo.productInfo.delivery.dataE"
                  class="bottom-input"
                  size="small"
              /></span>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <p>质保到期日</p>
            <div>
              <span
                ><el-input
                  type="date"
                  v-model="listInfo.productInfo.warranty.dataS"
                  class="bottom-input"
                  size="small"
              /></span>
              ~
              <span
                ><el-input
                  type="date"
                  v-model="listInfo.productInfo.warranty.dataE"
                  class="bottom-input"
                  size="small"
              /></span>
            </div>
          </td>
          <td>
            <p>返回日期</p>
            <div>
              <span
                ><el-input
                  type="date"
                  v-model="listInfo.Date.dataS"
                  class="bottom-input"
                  size="small"
              /></span>
              ~
              <span
                ><el-input
                  type="date"
                  v-model="listInfo.Date.dataE"
                  class="bottom-input"
                  size="small"
              /></span>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <p>故障信息</p>
            <el-input
              type="text"
              v-model="listInfo.failureDescription.data"
              class="bottom-input"
              size="small"
            />
          </td>
          <td>
            <p>国外报告</p>
            <el-input
              type="text"
              v-model="listInfo.sigmatekReport.data"
              class="bottom-input"
              size="small"
            />
          </td>
          <td>
            <p>维修信息</p>
            <el-input
              type="text"
              v-model="listInfo.analysis.data"
              class="bottom-input"
              size="small"
            />
          </td>
        </tr>
      </table>

      <p>更换部件信息</p>
      <table>
        <tr>
          <td>
            <p>物料名称</p>
            <el-input
              type="text"
              v-model="listInfo.componentsInfomation.name.data"
              class="bottom-input"
              size="small"
            />
          </td>
          <td>
            <p>故障物料序列号</p>
            <el-input
              type="text"
              v-model="listInfo.componentsInfomation.badNr.data"
              class="bottom-input"
              size="small"
            />
          </td>
          <td>
            <p>订货号</p>
            <el-input
              type="text"
              v-model="listInfo.componentsInfomation.number.data"
              class="bottom-input"
              size="small"
            />
          </td>
          <td>
            <p>新物料序列号</p>
            <el-input
              type="text"
              v-model="listInfo.componentsInfomation.newNr.data"
              class="bottom-input"
              size="small"
            />
          </td>
          <td>
            <p>注释</p>
            <el-input
              type="text"
              v-model="listInfo.Comment.data.data"
              class="bottom-input"
              size="small"
            />
          </td>
        </tr>
      </table>
      <table>
        <tr>
          <td>
            <p>国外送出方式</p>
            <el-select v-model="listInfo.ups.sendType.data">
              <el-option
                v-for="item in upsSend"
                :key="item.jobId"
                :label="item.sendType"
                :value="item.sendType"
              />
            </el-select>
            <!-- <el-input type="text" v-model="upsAct.sendType"  class="bottom-input" size="small" /> -->
          </td>
          <td>
            <p>送出时间</p>
            <div>
              <span
                ><el-input
                  type="date"
                  v-model="listInfo.ups.sendDate.dataS"
                  class="bottom-input"
                  size="small"
              /></span>
              ~
              <span
                ><el-input
                  type="date"
                  v-model="listInfo.ups.sendDate.dataE"
                  class="bottom-input"
                  size="small"
              /></span>
            </div>
          </td>
          <td>
            <p>国外返回方式</p>
            <el-select v-model="listInfo.ups.backType.data">
              <el-option
                v-for="item in upsBack"
                :key="item.jobId"
                :label="item.sendType"
                :value="item.sendType"
              />
            </el-select>
            <!-- <el-input type="text" v-model="upsAct.backType"  class="bottom-input" size="small" /> -->
          </td>
          <td>
            <p>返回时间</p>

            <div>
              <span
                ><el-input
                  type="date"
                  v-model="listInfo.ups.backDate.dataS"
                  class="bottom-input"
                  size="small"
              /></span>
              ~
              <span
                ><el-input
                  type="date"
                  v-model="listInfo.ups.backDate.dataE"
                  class="bottom-input"
                  size="small"
              /></span>
            </div>
          </td>
        </tr>
      </table>
      <span slot="footer" class="dialog-footer">
        <el-button type="normal" v-on:click="clearFilter">Clear</el-button>
        <el-button type="primary" v-on:click="confirmFilter">Confirm</el-button>
      </span>
    </el-dialog>

    <el-dialog :visible.sync="showComment" title="附加">
      <!-- <table border="1" cellspacing ='0' id="repair-list" >
     
        <tr height ="40" v-for="(item,index) in comment.commentText" :key="index">
          <td class="delete" v-on:click="remove(index)">{{index+1}}</td>
          <td>  <el-input type="text" v-model="item.data"  class="edit-input" size="small" /></td>
        </tr>
        <tr height ="40" >
           <td class="create" v-on:click ="creatNew" width="40">...</td>
          <td width="860">  </td>
        </tr>
      </table> -->
      <el-tabs v-model="activeName" style="margin-top: 15px" type="border-card">
        <el-tab-pane
          v-for="item in tabMapOptions"
          :key="item.key"
          :label="item.label"
          :name="item.key"
        >
          <keep-alive>
            <tab-pane
              v-if="activeName == item.key"
              :comment="comment"
              :ups="ups"
              :type="item.key"
              @create="showCreatedTimes"
              @saveExtras="saveExtras"
            />
          </keep-alive>
        </el-tab-pane>
      </el-tabs>

      <span slot="footer" class="dialog-footer">
        <!-- <el-button type="primary" v-on:click="saveComment">保存</el-button> -->
        <el-button type="normal" v-on:click="closeExtra">关闭</el-button>
      </span>
    </el-dialog>

    <el-table
      v-loading="listLoading"
      :data="list"
      element-loading-text="Loading"
      border
      fit
      highlight-current-row
    >
      <el-table-column align="center" label="Rep. ID" width="95">
        <template slot-scope="scope">
          <span v-if="!scope.row.ticketNum">{{ scope.$index + (listQuery.page - 1) * listQuery.limit + 1 }}</span>
          <span v-else>{{scope.row.ticketNum}}</span>
        </template>
      </el-table-column>
      <el-table-column label="Company">
        <template slot-scope="scope">
          {{ scope.row.customerInfo.company
          }}<span v-if="checkFile(scope.row)" class="blue"
            ><i class="el-icon-paperclip" /></span
          ><span v-if="checkComment(scope.row)" class="red"
            ><i class="el-icon-tickets" /></span
          ><span v-if="checkUps(scope.row)" class="yellow"
            ><i class="el-icon-s-promotion"
          /></span>
        </template>
      </el-table-column>

      <el-table-column label="SN" width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.productInfo.SN }}
        </template>
      </el-table-column>
      <el-table-column label="Module" width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.productInfo.module }}
        </template>
      </el-table-column>
      <el-table-column label="Failure" align="center">
        <template slot-scope="scope">
          {{ scope.row.failureDescription }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="created_at"
        label="返修日期"
        width="160"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.productInfo.return }}</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="created_at"
        label="发货日期"
        width="160"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.productInfo.delivery }}</span>
        </template>
      </el-table-column>

      <el-table-column
        align="center"
        prop="created_at"
        label="返回日期"
        width="160"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.Date }}</span>
        </template>
      </el-table-column>
      <!--   <el-table-column-->
      <!--  align="center"-->
      <!--  label="状态"-->
      <!--  width="160"-->
      <!-->-->
      <!--  <template slot-scope="scope">-->
      <!--    <span>{{ scope.row.Status }}</span>-->
      <!--  </template>-->
      <!--</el-table-column>-->
      <el-table-column align="center" label="操作" width="250">
        <template slot-scope="scope">
          <router-link
            :to="{
              path: 'form',
              query: {
                key: scope.row._id,
                filters: listQuery, // orderNum : this.searchData.orderNo
              },
            }"
          >
            <el-button type="button">编辑</el-button>
          </router-link>
          <span class="inline"></span>
          <el-button v-on:click="openComment(scope.row)">附加</el-button>
          <el-button v-on:click="openDelete(scope.row)" type="gray"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <pagination
      v-show="total > 0"
      :total="total"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.limit"
      @pagination="fetchData"
    />
  </div>
</template>

<script>
import TabPane from "./components/TabPane";
import UploadExcelComponent from "@/components/UploadExcel/index.vue";
import { getList, SetShowToCustomerByList,SetStatusByList } from "@/api/table";
import FilenameOption from "./components/FilenameOption";
import AutoWidthOption from "./components/AutoWidthOption";
import BookTypeOption from "./components/BookTypeOption";
import { MessageBox, Message } from "element-ui";
import * as XLSX from "xlsx";
import {
  fetchList,
  fetchArticle,
  fetchPv,
  createArticle,
  updateArticle,
  deleteArticle,
  bulkUpdate,
} from "@/api/article";
import Pagination from "@/components/Pagination";
export default {
  components: {
    Pagination,
    FilenameOption,
    AutoWidthOption,
    BookTypeOption,
    UploadExcelComponent,
    TabPane,
  },
  filters: {
    statusFilter(status) {
      const statusMap = {
        published: "success",
        draft: "gray",
        deleted: "danger",
      };
      return statusMap[status];
    },
  },
  data() {
    return {
      filterSN: null,
      _id: null,
      list: [],
      tableData: [],
      tableHeader: [],
      upsSend: [
        {
          sendType: null,
        },
        {
          sendType: "none",
        },
        {
          sendType: "ups",
        },
        {
          sendType: "人员携带",
        },
        {
          sendType: "大件",
        },
      ],
      upsBack: [
        {
          sendType: null,
        },
        {
          sendType: "none",
        },
        {
          sendType: "商品",
        },
        {
          sendType: "人员携带",
        },
        {
          sendType: "大件",
        },
      ],
      showExcelUpload: false,
      ExcelList: [],
      tabMapOptions: [
        { label: "注释", key: "comment" },
        { label: "UPS", key: "ups" },
        { label: "附件", key: "extra" },
      ],
      activeName: "CN",
      createdTimes: 0,
      listLoading: true,
      showComment: false,
      showDelete: false,
      downloadLoading: false,
      autoWidth: true,
      bookType: "xlsx",
      ups: {
        sendType: null,
        sendDate: null,
        backType: null,
        backDate: null,
        upsOrder: null,
        comment: null,
      },
      comment: {
        id: null,
        commentText: [
          {
            data: null,
          },
        ],
      },
      total: 40,
      listInfo: {
        customerInfo: {
          company: {
            type: "string",
            data: null,
            isFull: false,
          },
          contactor: {
            type: "string",
            data: null,
            isFull: false,
          },
        },
        productInfo: {
          SN: {
            type: "number",
            dataS: null,
            dataE: null,
          },
          module: {
            type: "string",
            data: null,
            isFull: false,
          },
          articl: {
            type: "string",
            data: null,
            isFull: false,
          },
          hardware: {
            type: "string",
            data: null,
            isFull: false,
          },
          software: {
            type: "string",
            data: null,
            isFull: false,
          },
          report: {
            type: "string",
            data: null,
            isFull: false,
          },
          parts: {
            type: "string",
            data: null,
            isFull: false,
          },
          po: {
            type: "string",
            data: null,
            isFull: false,
          },
          return: {
            type: "date",
            dataS: null,
            dataE: null,
          },

          delivery: {
            type: "date",
            dataS: null,
            dataE: null,
          },
          warranty: {
            type: "date",
            dataS: null,
            dataE: null,
          },
        },
        failureDescription: {
          type: "string",
          data: null,
          isFull: false,
        },
        analysis: {
          type: "string",
          data: null,
          isFull: false,
        },
        componentsInfomation: {
          name: {
            type: "Elementstring",
            data: null,
            isFull: false,
          },
          newNr: {
            type: "Elementstring",
            data: null,
            isFull: false,
          },
          badNr: {
            type: "Elementstring",
            data: null,
            isFull: false,
          },
          number: {
            type: "Elementstring",
            data: null,
            isFull: false,
          },
        },
        Comment: {
          data: {
            type: "Elementstring",
            data: null,
            isFull: false,
          },
        },
        contactor: {
          type: "string",
          data: null,
          isFull: false,
        },
        Date: {
          type: "date",
          dataS: null,
          dataE: null,
        },
        sigmatekReport: {
          type: "string",
          data: null,
          isFull: false,
        },
        ups: {
          sendType: {
            type: "string",
            data: null,
            isFull: false,
          },
          sendDate: {
            type: "date",
            dataS: null,
            dataE: null,
          },
          backType: {
            type: "string",
            data: null,
            isFull: false,
          },
          backDate: {
            type: "date",
            dataS: null,
            dataE: null,
          },
          timer: null,
        },
      },
      showFilter: false,
      listQuery: {
        page: 1,
        limit: 10,
        importance: undefined,
        title: undefined,
        type: undefined,
        sort: "+id",
        filters: {},
      },
      QuickFilterItem: [],
      ProdutInfo: [],
    };
  },
  watch: {
    activeName(val) {
      this.$router.push(`${this.$route.path}?tab=${val}`);
    },
    filterSN(val) {
      this.filterbySN();
    },
  },
  computed: {
    filename: function () {
      let filename = "";
      if (this.listInfo.customerInfo.company.data) {
        filename += this.listInfo.customerInfo.company.data + " ";
      }
      if (this.listInfo.customerInfo.contactor.data) {
        filename += this.listInfo.customerInfo.contactor.data + " ";
      }
      if (
        this.listInfo.productInfo.SN.dataS !== null &&
        this.listInfo.productInfo.SN.dataE !== null
      ) {
        filename +=
          "序列号：" +
          this.listInfo.productInfo.SN.dataS +
          "~" +
          this.listInfo.productInfo.SN.dataE +
          " ";
      } else if (this.listInfo.productInfo.SN.dataE) {
        filename += "序列号止于：" + this.listInfo.productInfo.SN.dataE + " ";
      } else if (this.listInfo.productInfo.SN.dataS) {
        filename += "序列号起于：" + this.listInfo.productInfo.SN.dataS + " ";
      }
      if (this.listInfo.productInfo.module.data) {
        filename += this.listInfo.productInfo.module.data + " ";
      }
      if (this.listInfo.productInfo.articl.data) {
        filename += this.listInfo.productInfo.articl.data + " ";
      }
      if (this.listInfo.productInfo.hardware.data) {
        filename += this.listInfo.productInfo.hardware.data + " ";
      }
      if (this.listInfo.productInfo.software.data) {
        filename += this.listInfo.productInfo.software.data + " ";
      }
      if (this.listInfo.productInfo.report.data) {
        filename += this.listInfo.productInfo.report.data + " ";
      }
      if (this.listInfo.productInfo.parts.data) {
        filename += this.listInfo.productInfo.parts.data + " ";
      }
      if (this.listInfo.productInfo.po.data) {
        filename += this.listInfo.productInfo.po.data + " ";
      }
      if (
        this.listInfo.productInfo.return.dataS !== null &&
        this.listInfo.productInfo.return.dataE !== null
      ) {
        filename +=
          "返修日期：" +
          this.listInfo.productInfo.return.dataS +
          "~" +
          this.listInfo.productInfo.return.dataE +
          " ";
      } else if (this.listInfo.productInfo.return.dataE) {
        filename +=
          "返修日期止于：" + this.listInfo.productInfo.return.dataE + " ";
      } else if (this.listInfo.productInfo.return.dataS) {
        filename +=
          "返修日期起于：" + this.listInfo.productInfo.return.dataS + " ";
      }
      if (
        this.listInfo.productInfo.delivery.dataS !== null &&
        this.listInfo.productInfo.delivery.dataE !== null
      ) {
        filename +=
          "发货日期：" +
          this.listInfo.productInfo.delivery.dataS +
          "~" +
          this.listInfo.productInfo.delivery.dataE +
          " ";
      } else if (this.listInfo.productInfo.delivery.dataE) {
        filename +=
          "发货日期止于：" + this.listInfo.productInfo.delivery.dataE + " ";
      } else if (this.listInfo.productInfo.delivery.dataS) {
        filename +=
          "发货日期起于：" + this.listInfo.productInfo.delivery.dataS + " ";
      }
      if (
        this.listInfo.productInfo.warranty.dataS !== null &&
        this.listInfo.productInfo.warranty.dataE !== null
      ) {
        filename +=
          "质保到期日：" +
          this.listInfo.productInfo.warranty.dataS +
          "~" +
          this.listInfo.productInfo.warranty.dataE +
          " ";
      } else if (this.listInfo.productInfo.warranty.dataE) {
        filename +=
          "质保到期日止于：" + this.listInfo.productInfo.warranty.dataE + " ";
      } else if (this.listInfo.productInfo.warranty.dataS) {
        filename +=
          "质保到期日起于：" + this.listInfo.productInfo.warranty.dataS + " ";
      }
      if (
        this.listInfo.Date.dataS !== null &&
        this.listInfo.Date.dataE !== null
      ) {
        filename +=
          "返回日期：" +
          this.listInfo.Date.dataS +
          "~" +
          this.listInfo.Date.dataE +
          " ";
      } else if (this.listInfo.Date.dataE) {
        filename += "返回日期止于：" + this.listInfo.Date.dataE + " ";
      } else if (this.listInfo.Date.dataS) {
        filename += "返回日期起于：" + this.listInfo.Date.dataS + " ";
      }
      if (this.listInfo.failureDescription.data) {
        filename += "故障信息：" + this.listInfo.failureDescription.data + " ";
      }
      if (this.listInfo.sigmatekReport.data) {
        filename += "国外报告：" + this.listInfo.sigmatekReport.data + " ";
      }
      if (this.listInfo.analysis.data) {
        filename += "维修信息：" + this.listInfo.analysis.data + " ";
      }
      if (this.listInfo.componentsInfomation.name.data) {
        filename +=
          "（更换部件）物料名称：" +
          this.listInfo.componentsInfomation.name.data +
          " ";
      }
      if (this.listInfo.componentsInfomation.badNr.data) {
        filename +=
          "（更换部件）故障物料序列号：" +
          this.listInfo.componentsInfomation.badNr.data +
          " ";
      }
      if (this.listInfo.componentsInfomation.number.data) {
        filename +=
          "（更换部件）订货号：" +
          this.listInfo.componentsInfomation.number.data +
          " ";
      }
      if (this.listInfo.componentsInfomation.newNr.data) {
        filename +=
          "（更换部件）新物料序列号：" +
          this.listInfo.componentsInfomation.newNr.data +
          " ";
      }
      if (this.listInfo.Comment.data.data) {
        filename +=
          "（更换部件）注释：" + this.listInfo.Comment.data.data + " ";
      }
      if (this.listInfo.ups.sendType.data) {
        filename += "国外送出方式：" + this.listInfo.ups.sendType.data + " ";
      }
      if (
        this.listInfo.ups.sendDate.dataS !== null &&
        this.listInfo.ups.sendDate.dataE !== null
      ) {
        filename +=
          "国外送出时间：" +
          this.listInfo.ups.sendDate.dataS +
          "~" +
          this.listInfo.ups.sendDate.dataE +
          " ";
      } else if (this.listInfo.ups.sendDate.dataE) {
        filename +=
          "国外送出时间止于：" + this.listInfo.ups.sendDate.dataE + " ";
      } else if (this.listInfo.ups.sendDate.dataS) {
        filename +=
          "国外送出时间起于：" + this.listInfo.ups.sendDate.dataS + " ";
      }
      if (this.listInfo.ups.backType.data) {
        filename += "国外返回方式：" + this.listInfo.ups.backType.data + " ";
      }
      if (
        this.listInfo.ups.backDate.dataS !== null &&
        this.listInfo.ups.backDate.dataE !== null
      ) {
        filename +=
          "国外返回时间：" +
          this.listInfo.ups.backDate.dataS +
          "~" +
          this.listInfo.ups.backDate.dataE +
          " ";
      } else if (this.listInfo.ups.backDate.dataE) {
        filename +=
          "国外返回时间止于：" + this.listInfo.ups.backDate.dataE + " ";
      } else if (this.listInfo.ups.backDate.dataS) {
        filename +=
          "国外返回时间起于：" + this.listInfo.ups.backDate.dataS + " ";
      }
      filename += this.GetDateNow;
      return filename;
    },
    GetDateNow: function () {
      const date = new Date();
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      const formattedDate = `${year}-${month}-${day}`;
      return formattedDate;
    },
  },
  mounted: function () {
    if (this.$route.query.filters) {
      this.listQuery = this.$route.query.filters;
    }
    this.fetchData();
  },
  created() {
    //  this.fetchData()
    //  const tab = this.$route.query.tab
    //if (tab) {
    this.activeName = "comment";
    //  }
  },
  methods: {
    saveExtras() {
      this.fetchData();
    },
    async fetchData() {
      this.listLoading = true;
      this.list = [];
      const { data } = await getList(this.listQuery);
      for (var i = 0; i < data.list.length; i++) {
        let obj = {
          customerInfo: {
            company: null,
            contactor: null,
            tel: null,
            fax: null,
            add: null,
          },
          productInfo: {
            SN: null,
            Module: null,
            articl: null,
            hardware: null,
            software: null,
            report: null,
            parts: null,
            po: null,
            return: null,
            delivery: null,
            warranty: null,
          },
          failureDescription: null,
          analysis: null,
          componentsInfomation: [
            {
              name: null,
              number: null,
              badNr: null,
              newNr: null,
              price: null,
            },
          ],
          back: false,
          times: null,
          contactor: null,
          Date: null,
          Comment: [],
          sigmatekReport: null,
          ups: {
            sendType: null,
            sendDate: null,
            backType: null,
            backDate: null,
            upsOrder: null,
            comment: null,
          },
        };
        this.list.push(obj);
        Object.assign(this.list[i], data.list[i]);
      }
      this.total = data.total;
      setTimeout(() => {
        this.listLoading = false;
        //console.log(this.list)
      }, 0.5 * 1000);
    },
    UploadUPSFile() {
      const input = document.createElement("input");
      input.type = "file";
      input.addEventListener("change", this.UploadUPSFileCb);
      input.accept =
        "application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      input.click();
    },
    UploadUPSFileCb(event) {
      const file = event.target.files[0];
            //弹出一个loading框
            this.$loading({
        lock: true,
        text: "正在解析文件...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const reader = new FileReader();
      reader.onload = (e) => {
        const data1 = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data1, { type: "array" });
        let jsonData = [];

        for (const sheet in workbook.Sheets) {
          if (workbook.Sheets.hasOwnProperty(sheet)) {
            const sheetData = XLSX.utils.sheet_to_json(workbook.Sheets[sheet]);
            jsonData=sheetData;
          }
        }
        this.$loading().close();
        this.$message({
          message: "文件解析成功",
          type: "success",
        });
        SetStatusByList({
          list:jsonData
        }).then(res=>{
          this.$loading().close();
          this.$message({
            message: "上传成功",
            type: "success",
          });
        }).catch(err=>{
          this.$loading().close();
          this.$message({
            message: "上传失败",
            type: "error",
          });
        })
      };
      reader.readAsArrayBuffer(file);
    },
    showCreatedTimes() {
      this.createdTimes = this.createdTimes + 1;
    },
    async bulkUpdate() {
      var datalist = [];

      for (var i = 0; i < this.tableData.length; i++) {
        if (
          this.tableData[i][this.tableHeader[8]] &&
          this.tableData[i][this.tableHeader[16]]
        ) {
          datalist.push({
            updateOne: {
              filter: {
                "productInfo.SN":
                  this.tableData[i][this.tableHeader[8]].toString(),
                times: this.tableData[i][this.tableHeader[16]].toString(),
              },
              update: {
                sigmatekReport: this.tableData[i][this.tableHeader[34]]
                  ? this.tableData[i][this.tableHeader[34]].toString()
                  : " ",
                "ups.sendDate": this.tableData[i][this.tableHeader[29]]
                  ? this.tableData[i][this.tableHeader[29]].toString()
                  : " ",
                // 'failureDescription':this.tableData[i][this.tableHeader[19]].toString(),
              },
            },
          });
        }
      }
      var a = 1;
      const { data } = await bulkUpdate({
        data: datalist,
      });
      if (data) {
        Message({
          message: "保存成功",
          type: "success",
          duration: 5 * 1000,
        });
      }
      this.showExcelUpload = false;
      this.fetchData();
    },
    async bulkSave() {
      var datalist = [];

      for (var i = 0; i < this.tableData.length; i++) {
        var componentsInfomation = [];
        if (this.tableData[i][this.tableHeader[21]]) {
          if (
            this.tableData[i][this.tableHeader[21]].toString().indexOf(",") !==
            -1
          ) {
            var parts = this.tableData[i][this.tableHeader[21]].split(",");
          } else {
            var parts = [this.tableData[i][this.tableHeader[21]]];
          }
        } else {
          var parts = [""];
        }
        if (this.tableData[i][this.tableHeader[22]]) {
          if (
            this.tableData[i][this.tableHeader[22]].toString().indexOf(",") !==
            -1
          ) {
            var number = this.tableData[i][this.tableHeader[22]].split(",");
          } else {
            var number = [this.tableData[i][this.tableHeader[22]]];
          }
        } else {
          var number = [""];
        }
        if (this.tableData[i][this.tableHeader[23]]) {
          if (
            this.tableData[i][this.tableHeader[23]].toString().indexOf(",") !==
            -1
          ) {
            var oldNr = this.tableData[i][this.tableHeader[23]].split(",");
          } else {
            var oldNr = [this.tableData[i][this.tableHeader[23]]];
          }
        } else {
          var oldNr = [""];
        }
        if (this.tableData[i][this.tableHeader[24]]) {
          if (
            this.tableData[i][this.tableHeader[24]].toString().indexOf(",") !==
            -1
          ) {
            var newNr = this.tableData[i][this.tableHeader[24]].split(",");
          } else {
            var newNr = [this.tableData[i][this.tableHeader[24]]];
          }
        } else {
          var newNr = [""];
        }
        if (this.tableData[i][this.tableHeader[25]]) {
          if (
            this.tableData[i][this.tableHeader[25]].toString().indexOf(",") !==
            -1
          ) {
            var price = this.tableData[i][this.tableHeader[25]].split(",");
          } else {
            var price = [this.tableData[i][this.tableHeader[25]]];
          }
        } else {
          var price = [""];
        }
        if (parts) {
          for (var j = 0; j < parts.length; j++) {
            componentsInfomation.push({
              name: parts[j],
              number: number[j],
              badNr: oldNr[j],
              newNr: newNr[j],
              price: price[j],
            });
          }
        }

        var comment = [];
        var commentString = [];

        if (this.tableData[i][this.tableHeader[27]]) {
          if (
            this.tableData[i][this.tableHeader[27]].toString().indexOf(",") !==
            -1
          ) {
            commentString = this.tableData[i][this.tableHeader[27]].split(",");
          } else {
            commentString = [this.tableData[i][this.tableHeader[27]]];
          }
        } else {
          commentString = [];
        }

        for (var j = 0; j < commentString.length; j++) {
          comment.push({
            data: commentString[j],
          });
        }
        datalist.push({
          insertOne: {
            document: {
              customerInfo: {
                company: this.tableData[i][this.tableHeader[2]],
                companyEn: this.tableData[i][this.tableHeader[3]],
                contactor: this.tableData[i][this.tableHeader[4]],
                tel: this.tableData[i][this.tableHeader[5]],
                fax: this.tableData[i][this.tableHeader[6]],
                add: this.tableData[i][this.tableHeader[7]],
              },
              productInfo: {
                SN: this.tableData[i][this.tableHeader[8]],
                module: this.tableData[i][this.tableHeader[9]],
                articl: this.tableData[i][this.tableHeader[10]],
                hardware: this.tableData[i][this.tableHeader[11]],
                software: this.tableData[i][this.tableHeader[12]],
                report: this.tableData[i][this.tableHeader[13]],
                parts: this.tableData[i][this.tableHeader[14]],
                po: this.tableData[i][this.tableHeader[15]],
                return: this.tableData[i][this.tableHeader[1]],
                delivery: this.tableData[i][this.tableHeader[17]],
                warranty: this.tableData[i][this.tableHeader[18]],
                Quote: this.tableData[i][this.tableHeader[35]],
                reportNumber: this.tableData[i][this.tableHeader[36]],
              },
              faultclass: this.tableData[i][this.tableHeader[37]],
              faultSecondclass: this.tableData[i][this.tableHeader[38]],
              failureDescription: this.tableData[i][this.tableHeader[19]],
              analysis: this.tableData[i][this.tableHeader[20]],
              componentsInfomation: componentsInfomation,
              back: this.tableData[i][this.tableHeader[26]],
              times: this.tableData[i][this.tableHeader[16]],
              contactor: null,
              Date: this.tableData[i][this.tableHeader[33]],
              sigmatekReport: this.tableData[i][this.tableHeader[34]],
              Comment: comment,
              ups: {
                sendType: this.tableData[i][this.tableHeader[28]],
                sendDate: this.tableData[i][this.tableHeader[29]],
                backType: this.tableData[i][this.tableHeader[31]],
                backDate: this.tableData[i][this.tableHeader[32]],
                upsOrder: this.tableData[i][this.tableHeader[30]],
              },
            },
          },
        });
      }
      const { data } = await bulkUpdate({
        data: datalist,
      });
      if (data) {
        Message({
          message: "保存成功",
          type: "success",
          duration: 5 * 1000,
        });
      }
      this.showExcelUpload = false;
      this.fetchData();
    },
    async handleDownload() {
      this.downloadLoading = true;
      this.ExcelList = [];
      const { data } = await getList({
        page: 1,
        limit: 5000,
        filters: this.listQuery.filters,
      });
      for (var i = 0; i < data.list.length; i++) {
        let obj = {
          customerInfo: {
            company: null,
            contactor: null,
            tel: null,
            fax: null,
            add: null,
          },
          productInfo: {
            SN: null,
            Module: null,
            articl: null,
            hardware: null,
            software: null,
            report: null,
            parts: null,
            po: null,
            return: null,
            delivery: null,
            warranty: null,
            Quote: null,
            reportNumber: null,
          },
          faultclass: null,
          faultSecondclass: null,
          failureDescription: null,
          analysis: null,
          componentsInfomation: [
            {
              name: null,
              number: null,
              badNr: null,
              newNr: null,
              price: null,
            },
          ],
          back: false,
          times: null,
          contactor: null,
          Date: null,
          Comment: [],
          sigmatekReport: null,
          ups: {
            sendType: null,
            sendDate: null,
            backType: null,
            backDate: null,
            upsOrder: null,
            comment: null,
          },
        };
        this.ExcelList.push(obj);
        Object.assign(this.ExcelList[i], data.list[i]);
      }
      import("@/vendor/Export2Excel").then((excel) => {
        const tHeader = [
          "Id",
          "Failure data",
          "Company",
          "CompanyEng",
          "Contactor",
          "Tel",
          "Fax.",
          "Add.",
          "SN",
          "Module",
          "Articl Nr",
          "HW",
          "SW",
          "Report No",
          "Parts No",
          "PO No.",
          "Back Times",
          "Delivery Date",
          "Warranty Date",
          "Failure Description",
          "Analysis",
          "Components Parts",
          "Components Articl No.",
          "Components Defect Part No.",
          "Components New part No.",
          "Components Price",
          "Return to supply",
          "Comment",
          "Ups Send way",
          "Ups Send date",
          "Ups Send order",
          "Ups back way",
          "Ups back date",
          "Return data",
          "Sigmatek Report",
          "Quote",
          "reportNumber",
          "root",
          "root Descrip",
        ];
        const filterVal = [
          "id",
          "productInfo.return",
          "customerInfo.company",
          "customerInfo.companyEn",
          "customerInfo.contactor",
          "customerInfo.tel",
          "customerInfo.fax",
          "customerInfo.add",
          "productInfo.SN",
          "productInfo.module",
          "productInfo.articl",
          "productInfo.hardware",
          "productInfo.software",
          "productInfo.report",
          "productInfo.parts",
          "productInfo.po",
          "times",
          "productInfo.delivery",
          "productInfo.warranty",
          "failureDescription",
          "analysis",
          "componentsInfomation.name",
          "componentsInfomation.number",
          "componentsInfomation.badNr",
          "componentsInfomation.newNr",
          "componentsInfomation.price",
          "back",
          "Comment.data",
          "ups.sendType",
          "ups.sendDate",
          "ups.upsOrder",
          "ups.backType",
          "ups.backDate",
          "Date",
          "sigmatekReport",
          "productInfo.Quote",
          "productInfo.reportNumber",
          "faultclass",
          "faultSecondclass",
        ];
        const list = this.ExcelList;
        const data = this.formatJson(filterVal, list);

        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: this.filename,
          autoWidth: this.autoWidth,
          bookType: this.bookType,
        });
        this.downloadLoading = false;
      });
    },
    beforeUpload(file) {
      if (file.name.substring(file.name.lastIndexOf(".") + 1) != "xlsx") {
        this.$message({
          message: "不允许xlsx以外的文件",
          type: "warning",
        });
        return false;
      }
      const isLt1M = file.size / 1024 / 1024 < 10;

      if (isLt1M) {
        return true;
      }

      this.$message({
        message: "Please do not upload files larger than 1m in size.",
        type: "warning",
      });
      return false;
    },
    handleSuccess({ results, header }) {
      this.tableData = results;
      this.tableHeader = header;
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map((v, index) =>
        filterVal.map((j) => {
          if (j === "id") {
            return index + 1;
          } else if (j.toString().indexOf(".") != -1) {
            let a = j.split(".");
            if (a[0] == "componentsInfomation" || a[0] == "Comment") {
              var string = "";
              var num = 13;
              for (var i = 0; i < v[a[0]].length; i++) {
                if (v[a[0]][i][a[1]] !== null) {
                  string += v[a[0]][i][a[1]];
                  string += ",";
                }
              }
              return string.substring(0, string.length - 1);
            } else {
              return v[a[0]][a[1]];
            }
          } else {
            return v[j];
          }
        })
      );
    },
    buttonType(data) {
      if (data.Comment.length > 0) {
        return "danger";
      } else {
        return "normal";
      }
    },
    remove(index) {
      this.comment.commentText.splice(index, 1);
    },
    async saveComment() {
      if (this.comment.id) {
        const updateData = {
          id: this.comment.id,
          data: {
            Comment: this.comment.commentText,
          },
        };
        const { data } = await updateArticle(updateData);
        if (data) {
          this.showComment = false;
        }
      }
    },
    creatNew() {
      if (this.comment.commentText.length < 10) {
        this.comment.commentText.push({
          data: null,
        });
      }
    },
    async newForm() {
      var date = new Date();
      var seperator1 = "-";
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var strDate = date.getDate();
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
      }
      var currentdate = year + seperator1 + month + seperator1 + strDate;

      const { data } = await createArticle({
        customerInfo: {
          company: null,
          contactor: null,
          tel: null,
          fax: null,
          add: null,
        },
        productInfo: {
          SN: null,
          Module: null,
          articl: null,
          hardware: null,
          software: null,
          report: null,
          parts: null,
          po: null,
          return: currentdate,

          delivery: null,
          warranty: null,
        },
        failureDescription: null,
        analysis: null,
        componentsInfomation: [
          {
            name: null,
            count: 1,
            number: null,
            badNr: null,
            newNr: null,
            price: null,
          },
        ],
        SuppliercomponentsInfomation: [
          {
            name: null,
            count: 1,
            number: null,
            badNr: null,
            newNr: null,
            price: null,
          },
        ],
        back: false,
        SupplierRelated: {
          Sigmatek_MessageToSupplier: null,
          Supplier_Complete: null,
          Supplier_TotalPrice: null,
          Supplier_TrackingNumber: null,
          Supplier_DeliveryDate: null,
        },
        times: null,
        contactor: null,
        Date: null,
        sigmatekReport: null,
        Comment: [],
        ups: {
          sendType: null,
          sendDate: null,
          backType: null,
          backDate: null,
          upsOrder: null,
          comment: null,
        },
      });
      this.fetchData();
    },
    checkFile(row) {
      if (row.appendix) {
        if (row.appendix.length > 0) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    checkComment(row) {
      if (row.Comment) {
        if (row.Comment.length > 0 && row.Comment[0].data.length > 0) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    checkUps(row) {
      if (row.ups.sendType) {
        return true;
      } else {
        return false;
      }
    },
    openDelete(data) {
      this._id = data._id;
      this.showDelete = true;
    },
    closeExtra() {
      this.fetchData();
      this.showComment = false;
    },
    async deleteInfo() {
      const { data } = await deleteArticle({ id: this._id });
      //   var a =data ;
      if (data) {
        Message({
          message: "删除成功",
          type: "success",
          duration: 5 * 1000,
        });
      }

      this.showDelete = false;
      this.fetchData();
    },
    openComment(data) {
      this.comment = {
        id: null,
        commentText: [
          {
            data: null,
          },
        ],
      };
      this.comment.id = data._id;
      Object.assign(this.ups, data.ups);
      Object.assign(this.comment.commentText, data.Comment);
      // this.comment.commentText = data.Comment ;
      this.showComment = true;
    },
    confirmFilter() {
      //  this.listQuery.filters = {

      //         $or:[{
      //        "productInfo.SN":{
      //           $regex: '/^.*141.*$/'
      //        }},{
      //        "customerInfo.company":{
      //           $regex: '/^.*德马格.*$/'
      //        }
      //       }]
      //       ,
      //        Date:{
      //          $regex: '/^.*2021.*$/'
      //       }
      // };
      this.listQuery.filters = "({$and:[";

      if (this.listInfo.customerInfo.company.data) {
        if (this.listInfo.customerInfo.company.isFull) {
          this.listQuery.filters +=
            "'customerInfo.company':'" +
            this.listInfo.customerInfo.company.data +
            "',";
        } else {
          this.listQuery.filters +=
            "{'customerInfo.company':{ $regex:/^.*" +
            this.listInfo.customerInfo.company.data +
            ".*$/,$options: 'i'}},";
        }
      }
      if (this.listInfo.customerInfo.contactor.data) {
        if (this.listInfo.customerInfo.contactor.isFull) {
          this.listQuery.filters +=
            "'customerInfo.contactor':'" +
            this.listInfo.customerInfo.contactor.data +
            "',";
        } else {
          this.listQuery.filters +=
            "{'customerInfo.contactor':{ $regex:/^.*" +
            this.listInfo.customerInfo.contactor.data +
            ".*$/,$options: 'i'}},";
        }
      }

      if (this.listInfo.productInfo.module.data) {
        if (this.listInfo.productInfo.module.isFull) {
          this.listQuery.filters +=
            "'productInfo.module':'" +
            this.listInfo.productInfo.module.data +
            "',";
        } else {
          //屏蔽大小写$options: 'i'
          this.listQuery.filters +=
            "{'productInfo.module':{ $regex:/^.*" +
            this.listInfo.productInfo.module.data +
            ".*$/,$options: 'i'}},";
        }
      }
      if (this.listInfo.productInfo.articl.data) {
        if (this.listInfo.productInfo.articl.isFull) {
          this.listQuery.filters +=
            "'productInfo.articl':'" +
            this.listInfo.productInfo.articl.data +
            "',";
        } else {
          this.listQuery.filters +=
            "{'productInfo.articl':{ $regex:/^.*" +
            this.listInfo.productInfo.articl.data +
            ".*$/,$options: 'i'}},";
        }
      }
      if (this.listInfo.productInfo.hardware.data) {
        if (this.listInfo.productInfo.hardware.isFull) {
          this.listQuery.filters +=
            "'productInfo.hardware':'" +
            this.listInfo.productInfo.hardware.data +
            "',";
        } else {
          this.listQuery.filters +=
            "{'productInfo.hardware':{ $regex:/^.*" +
            this.listInfo.productInfo.hardware.data +
            ".*$/,$options: 'i'}},";
        }
      }
      if (this.listInfo.productInfo.software.data) {
        if (this.listInfo.productInfo.software.isFull) {
          this.listQuery.filters +=
            "'productInfo.software':'" +
            this.listInfo.productInfo.software.data +
            "',";
        } else {
          this.listQuery.filters +=
            "{'productInfo.software':{ $regex:/^.*" +
            this.listInfo.productInfo.software.data +
            ".*$/,$options: 'i'}},";
        }
      }
      if (this.listInfo.productInfo.report.data) {
        if (this.listInfo.productInfo.report.isFull) {
          this.listQuery.filters +=
            "'productInfo.report':'" +
            this.listInfo.productInfo.report.data +
            "',";
        } else {
          this.listQuery.filters +=
            "{'productInfo.report':{ $regex:/^.*" +
            this.listInfo.productInfo.report.data +
            ".*$/,$options: 'i'}},";
        }
      }
      if (this.listInfo.productInfo.parts.data) {
        if (this.listInfo.productInfo.parts.isFull) {
          this.listQuery.filters +=
            "'productInfo.parts':'" +
            this.listInfo.productInfo.parts.data +
            "',";
        } else {
          this.listQuery.filters +=
            "{'productInfo.parts':{ $regex:/^.*" +
            this.listInfo.productInfo.parts.data +
            ".*$/,$options: 'i'}},";
        }
      }
      if (this.listInfo.productInfo.po.data) {
        if (this.listInfo.productInfo.po.isFull) {
          this.listQuery.filters +=
            "'productInfo.po':'" + this.listInfo.productInfo.po.data + "',";
        } else {
          this.listQuery.filters +=
            "{'productInfo.po':{ $regex:/^.*" +
            this.listInfo.productInfo.po.data +
            ".*$/,$options: 'i'}},";
        }
      }
      if (this.listInfo.failureDescription.data) {
        // if (this.listInfo.failureDescription.isFull) {
        //   this.listQuery.filters +=
        //     "'failureDescription':'" +
        //     this.listInfo.failureDescription.data +
        //     "',";
        // } else {
        //   this.listQuery.filters +=
        //     "{'failureDescription':{ $regex:/^.*" +
        //     this.listInfo.failureDescription.data +
        //     ".*$/,$options: 'i'}},";
        // }
        this.listQuery.filters +=
            "({$text:{ $search:'" +
            this.listInfo.failureDescription.data +
            "'}}),";
      }
      if (this.listInfo.analysis.data) {
        if (this.listInfo.failureDescription.isFull) {
          this.listQuery.filters +=
            "'analysis':'" + this.listInfo.analysis.data + "',";
        } else {
          this.listQuery.filters +=
            "{'analysis':{ $regex:/^.*" +
            this.listInfo.analysis.data +
            ".*$/,$options: 'i'}},";
        }
      }

      if (this.listInfo.contactor.data) {
        if (this.listInfo.contactor.isFull) {
          this.listQuery.filters +=
            "'contactor':'" + this.listInfo.contactor.data + "',";
        } else {
          this.listQuery.filters +=
            "{'contactor':{ $regex:/^.*" +
            this.listInfo.contactor.data +
            ".*$/,$options: 'i'}},";
        }
      }

      if (this.listInfo.sigmatekReport.data) {
        if (this.listInfo.sigmatekReport.isFull) {
          this.listQuery.filters +=
            "'sigmatekReport':'" + this.listInfo.sigmatekReport.data + "',";
        } else {
          this.listQuery.filters +=
            "{'sigmatekReport':{ $regex:/^.*" +
            this.listInfo.sigmatekReport.data +
            ".*$/,$options: 'i'}},";
        }
      }
      if (this.listInfo.ups.sendType.data) {
        if (this.listInfo.ups.sendType.isFull) {
          this.listQuery.filters +=
            "'ups.sendType':'" + this.listInfo.ups.sendType.data + "',";
        } else {
          if (this.listInfo.ups.sendType.data == "none") {
            this.listQuery.filters += "{'ups.sendType':null},";
          } else {
            this.listQuery.filters +=
              "{'ups.sendType':{ $regex:/^.*" +
              this.listInfo.ups.sendType.data +
              ".*$/,$options: 'i'}},";
          }
        }
      }
      if (this.listInfo.ups.backType.data) {
        if (this.listInfo.ups.backType.isFull) {
          this.listQuery.filters +=
            "'ups.backType':'" + this.listInfo.ups.backType.data + "',";
        } else {
          if (this.listInfo.ups.backType.data == "none") {
            this.listQuery.filters += "{'ups.backType':null},";
          } else {
            this.listQuery.filters +=
              "{'ups.backType':{ $regex:/^.*" +
              this.listInfo.ups.backType.data +
              ".*$/,$options: 'i'}},";
          }
        }
      }
      if (this.listInfo.componentsInfomation.name.data) {
        if (this.listInfo.componentsInfomation.name.isFull) {
          this.listQuery.filters +=
            "'componentsInfomation':{$elemMatch:{'name':'" +
            this.listInfo.componentsInfomation.name.data +
            "'}},";
        } else {
          this.listQuery.filters += "{$and:[";
          var name = this.listInfo.componentsInfomation.name.data.split("-");
          for (var i = 0; i < name.length; i++) {
            this.listQuery.filters +=
              "{'componentsInfomation.name':{ $regex:/^.*" +
              name[i] +
              ".*$/,$options: 'i'}},";
          }
          this.listQuery.filters += "]},";
        }
      }

      if (this.listInfo.componentsInfomation.badNr.data) {
        if (this.listInfo.componentsInfomation.badNr.isFull) {
          this.listQuery.filters +=
            "'componentsInfomation':{$elemMatch:{'badNr':'" +
            this.listInfo.componentsInfomation.badNr.data +
            "'}},";
        } else {
          this.listQuery.filters += "{$and:[";
          var name = this.listInfo.componentsInfomation.badNr.data.split("-");
          for (var i = 0; i < name.length; i++) {
            this.listQuery.filters +=
              "{'componentsInfomation.badNr':{ $regex:/^.*" +
              name[i] +
              ".*$/}},";
          }
          this.listQuery.filters += "]},";
        }
      }
      if (this.listInfo.componentsInfomation.newNr.data) {
        if (this.listInfo.componentsInfomation.newNr.isFull) {
          this.listQuery.filters +=
            "'componentsInfomation':{$elemMatch:{'newNr':'" +
            this.listInfo.componentsInfomation.newNr.data +
            "'}},";
        } else {
          this.listQuery.filters += "{$and:[";
          var name = this.listInfo.componentsInfomation.newNr.data.split("-");
          for (var i = 0; i < name.length; i++) {
            this.listQuery.filters +=
              "{'componentsInfomation.newNr':{ $regex:/^.*" +
              name[i] +
              ".*$/,$options: 'i'}},";
          }
          this.listQuery.filters += "]},";
        }
      }

      if (this.listInfo.componentsInfomation.number.data) {
        if (this.listInfo.componentsInfomation.number.isFull) {
          this.listQuery.filters +=
            "'componentsInfomation':{$elemMatch:{'number':'" +
            this.listInfo.componentsInfomation.number.data +
            "'}},";
        } else {
          this.listQuery.filters += "{$and:[";
          var name = this.listInfo.componentsInfomation.number.data.split("-");
          for (var i = 0; i < name.length; i++) {
            this.listQuery.filters +=
              "{'componentsInfomation.number':{ $regex:/^.*" +
              name[i] +
              ".*$/,$options: 'i'}},";
          }
          this.listQuery.filters += "]},";
        }
      }
      if (this.listInfo.Comment.data.data) {
        if (this.listInfo.Comment.data.isFull) {
          this.listQuery.filters +=
            "'Comment':{$elemMatch:{'data':'" +
            this.listInfo.Comment.data.data +
            "'}},";
        } else {
          this.listQuery.filters += "{$and:[";
          var name = this.listInfo.Comment.data.data.split("-");
          for (var i = 0; i < name.length; i++) {
            this.listQuery.filters +=
              "{'Comment.data':{ $regex:/^.*" +
              name[i] +
              ".*$/,$options: 'i'}},";
          }
          this.listQuery.filters += "]},";
        }
      }

      if (
        this.listInfo.productInfo.SN.dataS !== null &&
        this.listInfo.productInfo.SN.dataE !== null
      ) {
        this.listQuery.filters +=
          "{'productInfo.SN':{$gte:'" +
          this.listInfo.productInfo.SN.dataS +
          "',$lte:'" +
          this.listInfo.productInfo.SN.dataE +
          "'}},";
      } else if (this.listInfo.productInfo.SN.dataE) {
        this.listQuery.filters +=
          "{$or:[{'productInfo.SN':{$lte:'" +
          this.listInfo.productInfo.SN.dataE +
          "'}},{'productInfo.SN':null}]},";
      } else if (this.listInfo.productInfo.SN.dataS) {
        this.listQuery.filters +=
          "{'productInfo.SN':{$gte:'" +
          this.listInfo.productInfo.SN.dataS +
          "'}},";
      }
      // 举例
      // ({'$and':[{'productInfo.SN':{$gte:'10000000',$lte:'55555555'}},{}]})
      if (
        this.listInfo.productInfo.return.dataS !== null &&
        this.listInfo.productInfo.return.dataE !== null
      ) {
        this.listQuery.filters +=
          "({$expr:{$and:[" +
          "{$gte:[{$dateFromString:{dateString:'$productInfo.return'}},new Date('" +
          this.listInfo.productInfo.return.dataS +
          "T00:00:00.000Z')]}," +
          "{$lt: [{$dateFromString:{dateString:'$productInfo.return'}},new Date('" +
          this.listInfo.productInfo.return.dataE +
          "T23:59:59.000Z')]} " +
          "]}}),";

        // "({'$expr':{'$and':[{'$ne':[{'$dateFromString':{'dateString':'$productInfo.return'}}, null]},{'$gte':[{'$dateFromString':{'dateString':'$productInfo.return'}},new Date('" + this.listInfo.productInfo.return.dataS + "T00:00:00.000Z')]}, {'$lt':[{'$dateFromString':{'dateString':'$productInfo.return'}},new Date('" + this.listInfo.productInfo.return.dataE + "T00:00:00.000Z')]}]}}),";
        //原： "{'productInfo.return':{$gte:'" + this.listInfo.productInfo.return.dataS + "',$lte:'" + this.listInfo.productInfo.return.dataE + "'}},";
      } else if (this.listInfo.productInfo.return.dataE) {
        this.listQuery.filters +=
          "({$expr:{$and:[" +
          "{$lt: [{$dateFromString:{dateString:'$productInfo.return'}},new Date('" +
          this.listInfo.productInfo.return.dataE +
          "T23:59:59.000Z')]} " +
          "]}}),";
        //原："{$or:[{'productInfo.return':{$lte:'" + this.listInfo.productInfo.return.dataE + "'}},{'productInfo.return':null}]},";
      } else if (this.listInfo.productInfo.return.dataS) {
        this.listQuery.filters +=
          "({$expr:{$and:[" +
          "{$gte:[{$dateFromString:{dateString:'$productInfo.return'}},new Date('" +
          this.listInfo.productInfo.return.dataS +
          "T00:00:00.000Z')]} " +
          "]}}),";
        //原："{'productInfo.return':{$gte:'" + this.listInfo.productInfo.return.dataS + "'}},";
      }

      if (
        this.listInfo.productInfo.delivery.dataS &&
        this.listInfo.productInfo.delivery.dataE
      ) {
        this.listQuery.filters +=
          "({$expr:{$and:[" +
          "{$gte:[{$dateFromString:{dateString:'$productInfo.delivery'}},new Date('" +
          this.listInfo.productInfo.delivery.dataS +
          "T00:00:00.000Z')]}," +
          "{$lt: [{$dateFromString:{dateString:'$productInfo.delivery'}},new Date('" +
          this.listInfo.productInfo.delivery.dataE +
          "T23:59:59.000Z')]} " +
          "]}}),";
        //原： "{'productInfo.delivery':{$gte:'" + this.listInfo.productInfo.delivery.dataS + "',$lte:'" + this.listInfo.productInfo.delivery.dataE + "'}},";
      } else if (this.listInfo.productInfo.delivery.dataE) {
        this.listQuery.filters +=
          "({$expr:{$and:[" +
          "{$lt: [{$dateFromString:{dateString:'$productInfo.delivery'}},new Date('" +
          this.listInfo.productInfo.delivery.dataE +
          "T23:59:59.000Z')]} " +
          "]}}),";
        //原： "{$or:[{'productInfo.delivery':{$lte:'" + this.listInfo.productInfo.delivery.dataE + "'}},{'productInfo.delivery':null}]},";
      } else if (this.listInfo.productInfo.delivery.dataS) {
        this.listQuery.filters +=
          "({$expr:{$and:[" +
          "{$gte:[{$dateFromString:{dateString:'$productInfo.delivery'}},new Date('" +
          this.listInfo.productInfo.delivery.dataS +
          "T00:00:00.000Z')]} " +
          "]}}),";
        //原： "{'productInfo.delivery':{$gte:'" + this.listInfo.productInfo.delivery.dataS + "'}},";
      }

      if (
        this.listInfo.productInfo.warranty.dataS &&
        this.listInfo.productInfo.warranty.dataE
      ) {
        this.listQuery.filters +=
          "({$expr:{$and:[" +
          "{$gte:[{$dateFromString:{dateString:'$productInfo.warranty'}},new Date('" +
          this.listInfo.productInfo.warranty.dataS +
          "T00:00:00.000Z')]}," +
          "{$lt: [{$dateFromString:{dateString:'$productInfo.warranty'}},new Date('" +
          this.listInfo.productInfo.warranty.dataE +
          "T23:59:59.000Z')]} " +
          "]}}),";
        //原： "{'productInfo.warranty':{$gte:'" + this.listInfo.productInfo.warranty.dataS + "',$lte:'" + this.listInfo.productInfo.warranty.dataE + "'}},";
      } else if (this.listInfo.productInfo.warranty.dataE) {
        this.listQuery.filters +=
          "({$expr:{$and:[" +
          "{$lt: [{$dateFromString:{dateString:'$productInfo.warranty'}},new Date('" +
          this.listInfo.productInfo.warranty.dataE +
          "T23:59:59.000Z')]} " +
          "]}}),";
        //原： "{$or:[{'productInfo.warranty':{$lte:'" + this.listInfo.productInfo.warranty.dataE + "'}},{'productInfo.warranty':null}]},";
      } else if (this.listInfo.productInfo.warranty.dataS) {
        this.listQuery.filters +=
          "({$expr:{$and:[" +
          "{$gte:[{$dateFromString:{dateString:'$productInfo.warranty'}},new Date('" +
          this.listInfo.productInfo.warranty.dataS +
          "T00:00:00.000Z')]} " +
          "]}}),";
        //原： "{'productInfo.warranty':{$gte:'" + this.listInfo.productInfo.warranty.dataS + "'}},";
      }

      if (this.listInfo.Date.dataS && this.listInfo.Date.dataE) {
        this.listQuery.filters +=
          "({$expr:{$and:[" +
          "{$gte:[{$dateFromString:{dateString:'$Date'}},new Date('" +
          this.listInfo.Date.dataS +
          "T00:00:00.000Z')]}," +
          "{$lt: [{$dateFromString:{dateString:'$Date'}},new Date('" +
          this.listInfo.Date.dataE +
          "T23:59:59.000Z')]} " +
          "]}}),";
        //原： "{'Date':{$gte:'" + this.listInfo.Date.dataS + "',$lte:'" + this.listInfo.Date.dataE + "'}},";
      } else if (this.listInfo.Date.dataE) {
        this.listQuery.filters +=
          "({$expr:{$and:[" +
          "{$lt: [{$dateFromString:{dateString:'$Date'}},new Date('" +
          this.listInfo.Date.dataE +
          "T23:59:59.000Z')]} " +
          "]}}),";
        //原： "{$or:[{'Date':{$lte:'" + this.listInfo.Date.dataE + "'}},{'Date':null}]},";
      } else if (this.listInfo.Date.dataS) {
        this.listQuery.filters +=
          "({$expr:{$and:[" +
          "{$gte:[{$dateFromString:{dateString:'$Date'}},new Date('" +
          this.listInfo.Date.dataS +
          "T00:00:00.000Z')]} " +
          "]}}),";
        //原： "{'Date':{$gte:'" + this.listInfo.Date.dataS + "'}},";
      }

      if (
        this.listInfo.ups.sendDate.dataS &&
        this.listInfo.ups.sendDate.dataE
      ) {
        this.listQuery.filters +=
          "({$expr:{$and:[" +
          "{$gte:[{$dateFromString:{dateString:'$ups.sendDate'}},new Date('" +
          this.listInfo.ups.sendDate.dataS +
          "T00:00:00.000Z')]}," +
          "{$lt: [{$dateFromString:{dateString:'$ups.sendDate'}},new Date('" +
          this.listInfo.ups.sendDate.dataE +
          "T23:59:59.000Z')]} " +
          "]}}),";
        //原： "{'$gte':[{'$dateFromString':{'dateString':'$ups.sendDate'}},new Date('" + this.listInfo.ups.sendDate.dataS + "T00:00:00.000Z')]}, {'$lt':[{'$dateFromString':{'dateString':'$ups.sendDate'}},new Date('" + this.listInfo.ups.sendDate.dataE + "T00:00:00.000Z')]},";
      } else if (this.listInfo.ups.sendDate.dataE) {
        this.listQuery.filters +=
          "({$expr:{$and:[" +
          "{$lt: [{$dateFromString:{dateString:'$ups.sendDate'}},new Date('" +
          this.listInfo.ups.sendDate.dataE +
          "T23:59:59.000Z')]} " +
          "]}}),";
        //原： "{$or:[{'ups.sendDate':{$lte:'" + this.listInfo.ups.sendDate.dataE + "'}},{'ups.sendDate':null}]},";
      } else if (this.listInfo.ups.sendDate.dataS) {
        this.listQuery.filters +=
          "({$expr:{$and:[" +
          "{$gte:[{$dateFromString:{dateString:'$ups.sendDate'}},new Date('" +
          this.listInfo.ups.sendDate.dataS +
          "T00:00:00.000Z')]} " +
          "]}}),";
        //原： "{'ups.sendDate':{$gte:'" + this.listInfo.ups.sendDate.dataS + "'}},";
      }

      if (
        this.listInfo.ups.backDate.dataS &&
        this.listInfo.ups.backDate.dataE
      ) {
        this.listQuery.filters +=
          "({$expr:{$and:[" +
          "{$gte:[{$dateFromString:{dateString:'$ups.backDate'}},new Date('" +
          this.listInfo.ups.backDate.dataS +
          "T00:00:00.000Z')]}," +
          "{$lt: [{$dateFromString:{dateString:'$ups.backDate'}},new Date('" +
          this.listInfo.ups.backDate.dataE +
          "T23:59:59.000Z')]} " +
          "]}}),";
        //原： "{'Date':{$gte:'" + this.listInfo.ups.backDate.dataS + "',$lte:'" + this.listInfo.ups.backDate.dataE + "'}},";
      } else if (this.listInfo.ups.backDate.dataE) {
        this.listQuery.filters +=
          "({$expr:{$and:[" +
          "{$lt: [{$dateFromString:{dateString:'$ups.backDate'}},new Date('" +
          this.listInfo.ups.backDate.dataE +
          "T23:59:59.000Z')]} " +
          "]}}),";
        //原： "{$or:[{'ups.backDate':{$lte:'" + this.listInfo.ups.backDate.dataE + "'}},{'ups.backDate':null}]},";
      } else if (this.listInfo.ups.backDate.dataS) {
        this.listQuery.filters +=
          "({$expr:{$and:[" +
          "{$gte:[{$dateFromString:{dateString:'$ups.backDate'}},new Date('" +
          this.listInfo.ups.backDate.dataS +
          "T00:00:00.000Z')]} " +
          "]}}),";
        //原： "{'ups.backDate':{$gte:'" + this.listInfo.ups.backDate.dataS + "'}},";
      }

      this.listQuery.filters += "{}]})";
      //console.log(this.listQuery);
      this.QuickFilterItem = [];
      this.fetchData();
      this.showFilter = false;
    },

    filterbySN() {
      if (this.filterSN != "") {
        this.listQuery.filters =
          // "({'productInfo.SN':{$regex:'" + this.filterSN + "'}})";
          "({$or:[{'productInfo.SN':{$regex:'" + this.filterSN + "'}},{'ticketNum':{$regex:'" + this.filterSN + "'}}]})"
        this.fetchData();
      } else {
        this.listQuery.filters = "{}";
        this.fetchData();
      }
    },
    QuickFilter() {
      if (this.QuickFilterItem.length > 0) {
        this.listQuery.filters = "({$or:[";
        for (let i = 0; i < this.QuickFilterItem.length; i++) {
          this.listQuery.filters +=
            "{'productInfo.module':{ $regex:/^.*" +
            this.QuickFilterItem[i] +
            ".*$/,$options: 'i'}},";
        }
        this.listQuery.filters += "]})";
      } else {
        this.listQuery.filters = {};
      }
      //console.log(this.listQuery);
      this.listQuery.start = 0;
      this.nowPage = 0;
      this.fetchData();
    },
    UploadFixFile() {
      const input = document.createElement("input");
      input.type = "file";
      input.addEventListener("change", this.UploadFixFileCb);
      input.accept =
        "application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      input.click();
    },
    UploadFixFileCb(event) {
      const file = event.target.files[0];
      const reader = new FileReader();
      //弹出一个loading框
      this.$loading({
        lock: true,
        text: "正在解析文件...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let data = [];
      reader.onload = (e) => {
        const data1 = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data1, { type: "array" });
        for (const sheet in workbook.Sheets) {
          if (workbook.Sheets.hasOwnProperty(sheet)) {
            let jsonData = XLSX.utils.sheet_to_json(workbook.Sheets[sheet]);
            //分析所有的数据。要求:只包含数字和字母的数据且大于7位,且必须至少有一个数字
            for (let i = 0; i < jsonData.length; i++) {
              let item = jsonData[i];
              //遍历所有的key和value
              for (const key in item) {
                if (item.hasOwnProperty(key)) {
                  const element = item[key];
                  //判断是否为字符串
                  if (typeof element == "string") {
                    //判断是否为数字和字母
                    if (/^[A-Za-z0-9]+$/.test(element)) {
                      //判断是否大于7位
                      if (element.length > 7) {
                        //判断是否包含数字
                        if (/[0-9]/.test(element)) {
                          //判断是否已经存在
                          if (data.indexOf(element) == -1) {
                            data.push(element);
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        this.$loading().close();
        this.$message({
          message: "文件解析成功",
          type: "success",
        });
        //弹窗正在上传
        this.$loading({
          lock: true,
          text: "正在上传文件...",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
        SetShowToCustomerByList({
          list:data
        }).then(res=>{
          this.$loading().close();
          this.$message({
            message: "上传成功",
            type: "success",
          });
        }).catch(err=>{
          this.$loading().close();
          this.$message({
            message: "上传失败",
            type: "error",
          });
        })
      };
      reader.readAsArrayBuffer(file);
    },
    clearFilter() {
      this.listInfo = {
        customerInfo: {
          company: {
            type: "string",
            data: null,
            isFull: false,
          },
          contactor: {
            type: "string",
            data: null,
            isFull: false,
          },
        },
        productInfo: {
          SN: {
            type: "number",
            dataS: null,
            dataE: null,
          },
          module: {
            type: "string",
            data: null,
            isFull: false,
          },
          articl: {
            type: "string",
            data: null,
            isFull: false,
          },
          hardware: {
            type: "string",
            data: null,
            isFull: false,
          },
          software: {
            type: "string",
            data: null,
            isFull: false,
          },
          report: {
            type: "string",
            data: null,
            isFull: false,
          },
          parts: {
            type: "string",
            data: null,
            isFull: false,
          },
          po: {
            type: "string",
            data: null,
            isFull: false,
          },
          return: {
            type: "date",
            dataS: null,
            dataE: null,
          },

          delivery: {
            type: "date",
            dataS: null,
            dataE: null,
          },
          warranty: {
            type: "date",
            dataS: null,
            dataE: null,
          },
        },
        failureDescription: {
          type: "string",
          data: null,
          isFull: false,
        },
        analysis: {
          type: "string",
          data: null,
          isFull: false,
        },
        componentsInfomation: {
          name: {
            type: "Elementstring",
            data: null,
            isFull: false,
          },
          newNr: {
            type: "Elementstring",
            data: null,
            isFull: false,
          },
          badNr: {
            type: "Elementstring",
            data: null,
            isFull: false,
          },
          number: {
            type: "Elementstring",
            data: null,
            isFull: false,
          },
        },
        Comment: {
          data: {
            type: "Elementstring",
            data: null,
            isFull: false,
          },
        },
        contactor: {
          type: "string",
          data: null,
          isFull: false,
        },
        Date: {
          type: "date",
          dataS: null,
          dataE: null,
        },
        sigmatekReport: {
          type: "string",
          data: null,
          isFull: false,
        },
        ups: {
          sendType: {
            type: "string",
            data: null,
            isFull: false,
          },
          sendDate: {
            type: "date",
            dataS: null,
            dataE: null,
          },
          backType: {
            type: "string",
            data: null,
            isFull: false,
          },
          backDate: {
            type: "date",
            dataS: null,
            dataE: null,
          },
        },
      };
    },
  },
};
</script>

<style scoped>
span {
  display: inline-block;
}
#filterSelect {
  position: absolute;
  top: 20px;
  left: 30px;
  width: 80%;
  height: 800px;
  background: white;
}
.filter >>> .el-dialog {
  width: 80%;
}
.app-container {
  position: relative;
}
p {
  margin-top: 7px;
  margin-bottom: 7px;
}
.yellow {
  background-color: yellow;
  border-radius: 5px;
}
.red {
  background-color: rgba(243, 129, 158, 0.863);
  border-radius: 5px;
}
.blue {
  background-color: rgb(155, 155, 219);
  border-radius: 5px;
}
.delete:hover {
  background-image: url("../../../Img/delete.jpg");
  background-repeat: no-repeat;
  color: transparent;
  background-size: cover;
  cursor: pointer;
}
.inline {
  width: 7px;
}
.create:hover {
  background-image: url("../../../Img/create.jpg");
  background-repeat: no-repeat;
  color: transparent;
  background-size: cover;
  cursor: pointer;
}
div >>> .filterbysn {
  display: inline-block;
  width: 150px;
  margin-left: 10px;
}
</style>