var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "head" },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.showdialog } },
            [_vm._v("新建")]
          ),
          _c("el-input", {
            staticClass: "Fuzzy_search",
            attrs: { placeholder: "按照产品型号搜索搜素" },
            model: {
              value: _vm.Search,
              callback: function ($$v) {
                _vm.Search = $$v
              },
              expression: "Search",
            },
          }),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.FuzzySearch } },
            [_vm._v("搜索")]
          ),
          _c("span", { staticStyle: { "margin-left": "10px" } }, [
            _vm._v("筛选key:"),
          ]),
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.sortKey,
                callback: function ($$v) {
                  _vm.sortKey = $$v
                },
                expression: "sortKey",
              },
            },
            [
              _c(
                "el-radio",
                {
                  staticStyle: { "margin-left": "10px" },
                  attrs: { label: "productId" },
                },
                [_vm._v("订货号")]
              ),
              _c(
                "el-radio",
                {
                  staticStyle: { "margin-left": "10px" },
                  attrs: { label: "name" },
                },
                [_vm._v("产品型号")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "Main" }, [
        _c(
          "div",
          { staticClass: "tablediv" },
          [
            _c(
              "el-table",
              {
                ref: "comptable",
                staticStyle: { width: "100%" },
                attrs: { data: _vm.ProductData },
              },
              [
                _c("el-table-column", {
                  attrs: { type: "expand" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (props) {
                        return [
                          _c(
                            "el-table",
                            {
                              staticStyle: {
                                width: "90%",
                                "margin-left": "5%",
                              },
                              attrs: {
                                data: props.row.bomarr,
                                "default-sort": {
                                  prop: "bom.number",
                                  order: "ascending",
                                },
                              },
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  prop: "bom.Id",
                                  label: "物料名称",
                                  width: "380",
                                  align: "left",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "bom.parts",
                                  label: "物料型号",
                                  align: "left",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "bom.number",
                                  label: "订货号",
                                  align: "left",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "num",
                                  label: "消耗",
                                  width: "180",
                                  align: "left",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "name",
                    label: "产品型号",
                    width: "180",
                    align: "left",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "Input",
                    label: "输入规格",
                    width: "180",
                    align: "left",
                  },
                }),
                _c("el-table-column", {
                  attrs: { prop: "productId", label: "订货号", align: "left" },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "Bottomstring",
                    label: "标签底部文字信息",
                    align: "center",
                  },
                }),
                _c("el-table-column", {
                  attrs: { label: "产品文档", align: "center" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.showfile(scope.row)
                                },
                              },
                            },
                            [_vm._v("附件")]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { label: "问题记录", align: "center" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.showIssue(scope.row)
                                },
                              },
                            },
                            [_vm._v("问题")]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "identifier",
                    label: "序列号标识符",
                    align: "center",
                  },
                }),
                _c("el-table-column", {
                  attrs: { prop: "Hw", label: "硬件版本", align: "center" },
                }),
                _c("el-table-column", {
                  attrs: { prop: "KD", label: "KD表", align: "center" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.showKDDialog(scope.row)
                                },
                              },
                            },
                            [_vm._v("编辑")]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { prop: "tip", label: "描述", align: "center" },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "Ce_pass",
                    label: "ce标识符",
                    align: "center",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("el-checkbox", {
                            on: {
                              change: function ($event) {
                                return _vm.Ce_pass_change(scope.row)
                              },
                            },
                            model: {
                              value: scope.row.Ce_pass,
                              callback: function ($$v) {
                                _vm.$set(scope.row, "Ce_pass", $$v)
                              },
                              expression: "scope.row.Ce_pass",
                            },
                          }),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { width: "300", label: "操作" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-button",
                            {
                              attrs: { size: "mini", type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleEditInfo(
                                    scope.$index,
                                    scope.row
                                  )
                                },
                              },
                            },
                            [_vm._v("编辑信息")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { size: "mini", type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleEdit(scope.$index, scope.row)
                                },
                              },
                            },
                            [_vm._v("编辑硬件版本")]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            title: !_vm.Editmode ? "添加product" : "修改product",
            visible: _vm.dislogvisial,
            width: "30%",
            "close-on-click-modal": false,
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dislogvisial = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.dialogloading,
                  expression: "dialogloading",
                },
              ],
              attrs: {
                model: _vm.ShowProduct,
                "label-width": "120px",
                rules: _vm.addrules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "产品名称", prop: "name" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.ShowProduct.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.ShowProduct, "name", $$v)
                      },
                      expression: "ShowProduct.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "输入规格", prop: "Input" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.ShowProduct.Input,
                      callback: function ($$v) {
                        _vm.$set(_vm.ShowProduct, "Input", $$v)
                      },
                      expression: "ShowProduct.Input",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "订货号" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.ShowProduct.productId,
                      callback: function ($$v) {
                        _vm.$set(_vm.ShowProduct, "productId", $$v)
                      },
                      expression: "ShowProduct.productId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "标签底部文字" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.ShowProduct.Bottomstring,
                      callback: function ($$v) {
                        _vm.$set(_vm.ShowProduct, "Bottomstring", $$v)
                      },
                      expression: "ShowProduct.Bottomstring",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "标识符", prop: "identifier" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.ShowProduct.identifier,
                      callback: function ($$v) {
                        _vm.$set(_vm.ShowProduct, "identifier", $$v)
                      },
                      expression: "ShowProduct.identifier",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "硬件版本", prop: "Hw" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.ShowProduct.Hw,
                      callback: function ($$v) {
                        _vm.$set(_vm.ShowProduct, "Hw", $$v)
                      },
                      expression: "ShowProduct.Hw",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "产品描述", prop: "tip" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.ShowProduct.tip,
                      callback: function ($$v) {
                        _vm.$set(_vm.ShowProduct, "tip", $$v)
                      },
                      expression: "ShowProduct.tip",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  !_vm.Editmode
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.creativeProduct()
                            },
                          },
                        },
                        [_vm._v("立即创建")]
                      )
                    : _vm._e(),
                  _vm.Editmode
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.ChangeProduct()
                            },
                          },
                        },
                        [_vm._v("修改")]
                      )
                    : _vm._e(),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.dislogvisial = false
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "硬件版本修改",
            visible: _vm.HwUpdate,
            width: "80%",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.HwUpdate = $event
            },
          },
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-timeline",
                    [
                      _vm._l(_vm.showHwChangePath, function (item, index) {
                        return _c(
                          "el-timeline-item",
                          {
                            key: index,
                            attrs: {
                              timestamp: _vm.gettimestamps(item.time),
                              placement: "top",
                            },
                          },
                          [
                            _c("el-card", [
                              _c(
                                "div",
                                {
                                  staticClass: "clearfix",
                                  attrs: { slot: "header" },
                                  slot: "header",
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      staticStyle: { padding: "3px 0" },
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.selectShowBom(
                                            item.bom,
                                            item.version
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("Bom查看")]
                                  ),
                                ],
                                1
                              ),
                              _c("h4", [
                                _vm._v(
                                  "HW:" +
                                    _vm._s(
                                      item.version
                                        ? item.version
                                        : "修改Bom无更新"
                                    )
                                ),
                              ]),
                              _c("pre", [_vm._v(_vm._s(item.describes))]),
                              _c("p", [
                                _vm._v(
                                  "修改于" +
                                    _vm._s(_vm.gettimestamps(item.time))
                                ),
                              ]),
                            ]),
                          ],
                          1
                        )
                      }),
                      _c(
                        "el-timeline-item",
                        { attrs: { placement: "top" } },
                        [
                          _c(
                            "el-card",
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "clearfix",
                                  attrs: { slot: "header" },
                                  slot: "header",
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      staticStyle: { padding: "3px 0" },
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.selectActShowBom()
                                        },
                                      },
                                    },
                                    [_vm._v("Bom查看")]
                                  ),
                                ],
                                1
                              ),
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入新版本",
                                  onkeyup: "value=value.replace(/[^\\d.]/g,'')",
                                },
                                model: {
                                  value: _vm.newHwinfo.version,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.newHwinfo, "version", $$v)
                                  },
                                  expression: "newHwinfo.version",
                                },
                              }),
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  rows: 2,
                                  placeholder: "请输入修改描述",
                                },
                                model: {
                                  value: _vm.newHwinfo.describes,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.newHwinfo, "describes", $$v)
                                  },
                                  expression: "newHwinfo.describes",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c("h2", [_vm._v("版本号：" + _vm._s(_vm.actVersion))]),
                  _c(
                    "el-table",
                    {
                      staticStyle: { width: "100%", "margin-left": "10px" },
                      attrs: { data: _vm.actBomarr },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "bom.Id",
                          label: "物料名称",
                          width: "180",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "bom.number",
                          label: "订货号",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "num",
                          label: "消耗",
                          width: "60",
                          align: "center",
                        },
                      }),
                      _vm.isNewBom
                        ? _c("el-table-column", {
                            attrs: {
                              label: "操作",
                              width: "80",
                              align: "center",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            size: "mini",
                                            type: "error",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.bomDelete(
                                                scope.$index,
                                                _vm.actBomarr
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("删除")]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              1311281266
                            ),
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm.isNewBom
                    ? _c(
                        "el-form",
                        {
                          staticStyle: { width: "100%", "margin-left": "10px" },
                          attrs: { inline: true, model: _vm.newBom },
                        },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "物料" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "名称及订货号" },
                                  on: { change: _vm.handleSelectChange },
                                  model: {
                                    value: _vm.bomID,
                                    callback: function ($$v) {
                                      _vm.bomID = $$v
                                    },
                                    expression: "bomID",
                                  },
                                },
                                _vm._l(_vm.bomlist, function (item) {
                                  return _c("el-option", {
                                    key: item._id,
                                    attrs: {
                                      value: item._id,
                                      label: item.Id + "_" + item.number,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "数量" } },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.newBom.num,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.newBom, "num", $$v)
                                  },
                                  expression: "newBom.num",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "primary" },
                                  on: { click: _vm.onSubmit },
                                },
                                [_vm._v("添加")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { attrs: { id: "newhwbutton" } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.confirmHwChange },
                },
                [_vm._v("确定修改")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.HwUpdate = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "添加bom",
            visible: _vm.bomvisiable,
            width: "30%",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.bomvisiable = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticStyle: { "margin-left": "5px", "margin-bottom": "10px" } },
            [_vm._v("订货号：")]
          ),
          _c("el-input", {
            attrs: { placeholder: "请输入订货号" },
            model: {
              value: _vm.addBominfo.bomnumber,
              callback: function ($$v) {
                _vm.$set(_vm.addBominfo, "bomnumber", $$v)
              },
              expression: "addBominfo.bomnumber",
            },
          }),
          _c(
            "div",
            { staticStyle: { "margin-left": "5px", "margin-bottom": "10px" } },
            [_vm._v("消耗：")]
          ),
          _c("el-input", {
            attrs: { placeholder: "请输入数量" },
            model: {
              value: _vm.addBominfo.num,
              callback: function ($$v) {
                _vm.$set(_vm.addBominfo, "num", $$v)
              },
              expression: "addBominfo.num",
            },
          }),
          _c(
            "div",
            {
              staticStyle: {
                "margin-top": "10px",
                display: "flex",
                "justify-content": "flex-end",
              },
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "primary" },
                  on: { click: _vm.confirmAddBom },
                },
                [_vm._v("确定")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.bomvisiable = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.Showannex, title: _vm.productInfo.name },
          on: {
            "update:visible": function ($event) {
              _vm.Showannex = $event
            },
          },
        },
        [
          _c("p", [_vm._v("版本选择:")]),
          _c(
            "el-select",
            {
              staticStyle: { margin: "20px" },
              attrs: { placeholder: "选择版本" },
              on: { change: _vm.getFileList },
              model: {
                value: _vm.versionChoose,
                callback: function ($$v) {
                  _vm.versionChoose = $$v
                },
                expression: "versionChoose",
              },
            },
            _vm._l(_vm.productInfo.HwChangePath, function (item) {
              return _c("el-option", {
                key: item.version,
                attrs: { label: item.version, value: item.version },
              })
            }),
            1
          ),
          _c(
            "el-upload",
            {
              attrs: {
                action: "''",
                multiple: "",
                accept:
                  ".jpg, .jpeg, .png, .gif, .bmp, .JPG, .JPEG, .PBG, .GIF, .pdf",
                "http-request": _vm.submitList,
                "file-list": _vm.actVersionAppendix,
                "show-file-list": true,
                "on-remove": _vm.handleRemove,
                "before-remove": _vm.beforeRemove,
                "on-preview": _vm.handlePreview,
              },
            },
            [
              _c("el-button", { attrs: { size: "small", type: "primary" } }, [
                _vm._v("点击上传"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "添加问题记录", visible: _vm.showIssueHistory },
          on: {
            "update:visible": function ($event) {
              _vm.showIssueHistory = $event
            },
          },
        },
        [
          _c(
            "el-input",
            {
              staticClass: "edit-input",
              staticStyle: { "margin-top": "10px" },
              attrs: { type: "text", size: "small" },
              model: {
                value: _vm.newIssue,
                callback: function ($$v) {
                  _vm.newIssue = $$v
                },
                expression: "newIssue",
              },
            },
            [
              _c("el-button", {
                attrs: { slot: "append", icon: "el-icon-circle-plus" },
                on: { click: _vm.addNewIssue },
                slot: "append",
              }),
            ],
            1
          ),
          _c(
            "el-table",
            { attrs: { data: _vm.productInfo.problemList } },
            [
              _c("el-table-column", {
                attrs: { prop: "info", label: "问题", align: "center" },
              }),
              _c("el-table-column", {
                attrs: { label: "记录时间", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " + _vm._s(_vm.getDate(scope.row.logDate)) + " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "操作", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.status
                          ? _c("i", { staticClass: "el-icon-success" })
                          : _c(
                              "el-button",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.changeStatus(scope.row)
                                  },
                                },
                              },
                              [_vm._v(" 结束")]
                            ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "编辑KD表", visible: _vm.showKDform },
          on: {
            "update:visible": function ($event) {
              _vm.showKDform = $event
            },
          },
        },
        [
          _c(
            "el-table",
            { staticStyle: { width: "100%" }, attrs: { data: _vm.KDInfo } },
            [
              _c("el-table-column", {
                attrs: { label: "客户", "min-width": "400", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "100%" },
                            attrs: { placeholder: "请选择" },
                            model: {
                              value: scope.row.OrderNumberAbbr,
                              callback: function ($$v) {
                                _vm.$set(scope.row, "OrderNumberAbbr", $$v)
                              },
                              expression: "scope.row.OrderNumberAbbr",
                            },
                          },
                          _vm._l(_vm.customerInfo, function (item) {
                            return _c("el-option", {
                              key: item._id,
                              attrs: {
                                label: item.company,
                                value: item.OrderNumberAbbr,
                              },
                            })
                          }),
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "KD号", "min-width": "200", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-input", {
                          model: {
                            value: scope.row.id,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "id", $$v)
                            },
                            expression: "scope.row.id",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "删除", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-button", {
                          attrs: {
                            type: "danger",
                            size: "small",
                            circle: "",
                            icon: "el-icon-close",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.remove(scope.$index)
                            },
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "buttonadd" },
            [
              _c("el-button", {
                attrs: { type: "primary", icon: "el-icon-plus", circle: "" },
                on: { click: _vm.creatNew },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "ButtonGroup" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.SaveKDform()
                    },
                  },
                },
                [_vm._v("确认修改")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.showKDform = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }